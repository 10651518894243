import proxy from '../main'
export default {
    rules: {
        agencyName: [
            { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        agencyCode: [
            { required: true, message: "请输入机构编码", trigger: "blur" },
        ],
        longitude: [
            { required: true, message: "请输入机构所在经度", trigger: "blur" },
        ],
        latitude: [
            { required: true, message: "请输入机构所在纬度", trigger: "blur" },
        ],
        address: [
            { required: true, message: "请输入机构地址", trigger: "blur" },
        ],
        special: [
            { required: true, message: "请输入机构特点", trigger: "blur" },
        ],
    },
    roleProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
            if (node.root != true) {
                let aa = {
                    agencyName: '',
                    agencyCode: '',
                    agencyParentId: node.value,
                };
                proxy.axios
                    .post(
                        proxy.baseURL + "Agency/AgencyList",
                        aa,
                        proxy.headers()
                    )
                    .then((res) => {
                        let data = proxy.resolveData(res.data);
                        let childRoleList = data.agencyList;
                        let nodes = childRoleList.map((item) => ({
                            value: item.id,
                            label: item.agencyName,
                            leaf: item.hasChildren === false,
                        }));
                        resolve(nodes);
                    });
            }
        },
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号",width: "100"},
            { prop: "agencyName", label:"机构名称"},
            { prop: "parentAgencyName", label:"上级机构名称"},
            { prop: "agencyCode", label: "机构编号",width:"150"},
            { prop: "patientNums", label: "患者数量",width:"150"},
            { prop: "longitude", label: "经度",width: "120"},
            { prop: "latitude", label: "纬度",width: "120"},
            { prop: "address", label: "地址"},
            { prop: "special", label: "特点"},
        ];
    },
    uploadlist: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "agencyName", label: "机构名称",width:150},
            { prop: "agencyCode", label: "机构编码",width:150},
            { prop: "imei", label: "手表序列号" ,width:150},
            { prop: "phone", label: "手机号",width:150},
            { prop: "address", label: "地址",width:220},
            { prop: "longitude", label: "经度",width:140},
            { prop: "latitude", label: "纬度",width:140},
            { prop: "special", label: "机构特点"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作", width: "130" });
        return header;
    },
    loadagencyList: function () {
        var aa =  sessionStorage.getItem("agencyList")
        var bb = JSON.parse(aa)
        let obj = {
            id:bb[0].id,
            agencyName: bb[0].agencyName,
            parentAgencyName: proxy.parentAgencyName,
            longitude: 0,
            latitude:  0,
            hasChildren: true,
            isMainFunc: true,
            isShow: true,
        };
        return [obj];
    },
    getChildAgencyList: function (row, treeNode, resolve) {
        
        let childList = [];
        let aa = {
            agencyName: '',
            agencyCode: '',
            agencyParentId: row.id
        }
        proxy.axios.post(proxy.baseURL + "Agency/AgencyList", aa, proxy.headers()).then((res) => {
            let data = proxy.resolveData(res.data);
            childList = data.agencyList;
        }).then(() => {
            resolve(childList);
        });
    },

}
<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
              用户列表·共&nbsp;<span style="color: #FFA70D;font-size: 0.25rem;">{{ numTotal }}</span>&nbsp;人
            </div>
          </el-col>
        </div>
        <div class="searchlist">
          <el-col :span="3.5">
            <el-input
              v-model="userName"
              placeholder="请输入姓名"
              @change="setuserName"
              @keyup.enter.native="search"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="3.5">
            <el-input
            v-model.trim="mobile"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入手机号"
              maxlength="11"
              @change="setmobile"
              @keyup.enter.native="search"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="3.5">
            <el-input
              v-model="email"
              placeholder="请输入邮箱"
              @change="setemail"
              @keyup.enter.native="search"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="1.5">
            <div class="chaxun-button" @click="search"></div>
          </el-col>
          <el-col :span="1.5">
            <div class="chongzhi-button" @click="reset"></div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        userName: "",
        email: "",
        mobile: "",
      };
    },
    props: {
      numTotal: {
        type: Number,
        default: 0,
      },
      search: {
        type: Function,
        default: function () {},
      },
      reset: {
        type: Function,
        default: function () {},
      },
    },
    mounted() {
      this.load();
    },
    methods: {
      load() {
        this.userlogic.reset();
      },
      setuserName() {
        this.userlogic.searchObj.userName = this.userName;
        let interval = setInterval(() => {
          if (this.userlogic.searchObj.userName === "") {
            this.userName = "";
            clearInterval(interval);
          }
        }, 1000);
      },
      setemail() {
        this.userlogic.searchObj.email = this.email;
        let interval = setInterval(() => {
          if (this.userlogic.searchObj.email == "") {
            this.email = "";
            clearInterval(interval);
          }
        }, 1000);
      },
      setmobile() {
        this.userlogic.searchObj.mobile = this.mobile;
        console.log(this.userlogic.searchObj.mobile)
        let interval = setInterval(() => {
          if (this.userlogic.searchObj.mobile == "") {
            this.mobile = "";
            clearInterval(interval);
          }
        }, 1000);
      },
    },
  };
  </script>
  
  
  
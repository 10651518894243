import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        testType: 1,
    },
    page:1,
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "userName", label: "姓名",width:180},
            { prop: "heartRate", label: "心率(次/分)" },
            { prop: "oxygenation", label: "血氧(%)" },
            { prop: "pressureFormat", label: "血压(mmHg)",width:180},
            { prop: "sleepStatusName", label: "睡眠情况"},
            { prop: "callStatusName", label: "呼叫状态"},
            { prop: "isFall", label: "跌落报警"},
        ];
    },
    gethomeList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Patient/PatientTestRecordList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    reset: function () {
        this.searchObj = {  page: 1, testType: 1, };
    }
}
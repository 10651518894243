<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <excelFields
                    :fields="fields"
                    :fieldKeys="fieldKeys"
                    :exportData="exportData"
                    style="height: 1rem;"
                    />
                    <div class="search" style="height: 10%;margin-bottom: 0 !important;">
                    <deviceSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></deviceSearch>
                    </div>
                    <deviceList
                    class="customer-table"
                    :header-cell-style="{ 
                    background:'#0A1634',color:'#fff'}"
                    :tableHeader="this.devicelogic.listHeader()"
                    :tableData="devicelist"
                    :page="this.devicelogic.searchObj.page"
                    :analysisform="true"
                    :row-class-name="tableRowClassName"
                    />
                    <pager
                    :page="this.devicelogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import excelFields from "@/components/searchComponents/exportField.vue";
import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
import deviceList from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager";
export default {
    components: {
        basecom,
        top,
        excelFields,
        deviceList,
        pager,
        deviceSearch
    },
    data() {
        return {
            fields: [],
            fieldKeys: [],
            devicelist: [],
            page: 1,
            numTotal: 0,
            searchForm: {
                page: 0,
                phone: '',
                imei: '',
                chargeStatus: 0,
                watchStatus: 0,
                agencyId: '00000000-0000-0000-0000-000000000000'
            },            
        };
    },
    created(){
    },
    mounted() {
        this.loadFields();
        this.handleCurrentChange();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        loadFields: function () {
            let aa = {
                excelType: 1,
            };
            this.axios.post(this.baseURL + "Watch/GetExpootExcelField",aa,this.headers()).then((res) => {
                let data = this.resolveData(res.data);
                this.fields = data.fieldList;
                data.fieldList.forEach((element) => {
                    this.fieldKeys.push(element.key);
                });
            });
        },
        handleCurrentChange: function (page = 1) {
            this.devicelogic.searchObj.page = page;
            this.searchForm.page = page
            this.devicelogic.getWatchList().then((res) => {
                res.watchList.forEach(item=>{
                  item.battery = item.battery + '%'
                })
                this.devicelist = res.watchList;
                this.numTotal = res.total;
            });
        },
        exportData: function () {
            let excelheader = this.devicelogic.selectedProps;
            let allData = this.devicelogic.allData;
            if (excelheader.length == 0) {
                this.$message({ message: "请设置Excel表头字段!", type: "error" });
                return;
            }
            if (allData) {
                this.$confirm("确认要导出全部数据吗？")
                .then((_) => {
                    this.exportExcel();
                })
                .catch((_) => {});
            } else {
                this.$confirm("确认要导出吗？")
                .then((_) => {
                    this.exportExcel();
                })
                .catch((_) => {});
            }
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
        exportExcel: function () {
            let excelheader = this.devicelogic.selectedProps;
            var obj = {};
            this.fieldKeys.forEach((item) => {
                eval("obj." + item + "=" + excelheader.includes(item));
            });
            obj.allData = this.devicelogic.allData;
            obj.allProp = this.devicelogic.allProp;
            obj.pwd = this.devicelogic.pwd;
            this.searchForm.imei = this.devicelogic.searchObj.imei
            if(this.devicelogic.searchObj.chargeStatus != undefined ){
                this.searchForm.chargeStatus = this.devicelogic.searchObj.chargeStatus 
            }
            if(this.devicelogic.searchObj.watchStatus != undefined ){
                this.searchForm.watchStatus = this.devicelogic.searchObj.watchStatus 
            }
            if(this.devicelogic.searchObj.agencyId != undefined ){
                this.searchForm.agencyId = this.devicelogic.searchObj.agencyId 
            }
            let exportForm = {
                searchCondition: this.searchForm,
                excelField: obj,
            };
            this.axios.post(this.baseURL + "Watch/ExportWatchExcel",exportForm,this.headers()).then((res) => {
                let data = this.resolveData(res.data);
                let iframe = document.createElement("iframe");
                iframe.style = "display:none";
                iframe.src = this.baseURL + data;
                document.body.appendChild(iframe);
            });
        },
    },
};
</script>
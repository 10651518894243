<template>
    <div class="agepeoplenum-top"></div>
    <div class="agepeoplenum-bottom">
        <div class="agepeoplenum-bottom-top">
            <div class="agepeoplenum-bottom-top-xy">
                <div></div><span>血压</span> 
            </div>
            <div id="agepeoplenumtop-echarts"></div>
        </div>
        <div class="agepeoplenum-bottom-bottom">
            <div class="agepeoplenum-bottom-bottom-xy">
                <div></div><span>心率</span> 
            </div>
            <div id="agepeoplenumbottom-echarts"></div>
        </div>
        <div class="agepeoplenum-bottom-center">
            <div class="agepeoplenum-bottom-center-xy">
                <div></div><span>血氧</span> 
            </div>
            <pie/>
        </div>
    </div>
</template>
<script>
import pie from './pie.vue'
export default{
    components:{
        pie
    },
    data(){
        return{
        
        }
    },
    mounted(){
        this.charts.agepeoplenumtop()
        this.charts.agepeoplenumbottom()
    },
    methods:{
       
    }
}
</script>
<style>

</style>
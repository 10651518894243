<template>
    <div class="medical-list" style="overflow: hidden;">
        <div class="search" style="margin-top: -0.2rem;">
            <homeSearch
                :search="search"
                :reset="reset"
                :numTotal="numTotal"
            ></homeSearch>
        </div>
        <div class="inform-box" v-if="inform">
            <div class="inform-left">通知</div>
            <div class="inform-line"></div>
            <div class="inform-right">
                <div class="msg-box" ref="msgBox">
                    <span class="msg" ref="scrollMsg">{{ informdata }}</span>
                </div>
            </div>
        </div>
        <div class="watch-list">
            <div class="wudata" v-if="datashow">
                暂无数据
            </div>
            <div class="block" style="height:100%;width:100%;z-index: 9;">
                <el-carousel indicator-position="outside" interval="10000" @change="handleCurrentChange">
                    <el-carousel-item :label="item" v-for="item in this.totalPage" :key="item">
                    <div class="watchbox" v-for="(item,index) in userlist" :key="index">
                        <div class="watchbox-left">
                            <div class="left-name">
                                <span :class="{'home-no-color':item.onLine != '在线'}">{{ item.userName }}</span> 
                                <div class="left-isOnLineno" v-if="item.onLine != '在线'"></div>
                                <div class="left-isOnLine" v-if="item.onLine == '在线'"></div>
                            </div>
                            <div class="left-line"></div>
                            <div class="left-age">
                                <span style="margin-left:0.2rem" :class="{'home-no-color':item.onLine != '在线'}">{{ item.genderTypeName }}</span>
                                <div :class="{'home-no-color':item.onLine != '在线'}" class="left-age-line"></div>
                                <span>{{ item.age }}</span>
                                <div class="left-age-line"></div>
                                <div class="left-electricity">
                                    <div class="left-electricity-container" :class="{'battery-color':item.battery < 30}">
                                        <div style="width: 90%;height:85%;margin: auto;margin-top: 4%;">
                                            <div class="left-electricity-box" :style="{width: item.battery + '%'}"></div>  
                                            <div class="cding" v-if="item.chargeStatus == 1"></div>
                                        </div>
                                        <div class="left-electricity-dd" :class="{'dd-color':item.battery < 30}"></div>
                                    </div>
                                    <span style="margin-left: 0.1rem;">{{  item.battery  }}%</span>
                                </div>
                            </div>
                            <div class="left-line"></div>
                            <div class="left-imei">
                                <span>IMEI序列号:</span>
                                <div :class="{'home-no-color':item.onLine != '在线'}">{{ item.imeis }}</div>
                            </div>
                            <div class="left-line"></div>
                            <div class="left-userxq" :class="{'home-no-color-to':item.onLine != '在线'}">
                                <div class="xieyabottom" @click="down(item.id,6)" :class="{'xieyabottomfalse':item.onLine != '在线'}"></div>
                                <div class="xieyangbottom" @click="down(item.id,11)" :class="{'xieyangbottomfalse':item.onLine != '在线'}"></div>
                                <div class="xinlvbottom" @click="down(item.id,5)" :class="{'xinlvbottomfalse':item.onLine != '在线'}"></div>
                                <div class="xiangqinghome" @click="particulars(item)">查看详情&nbsp;></div> 
                            </div>
                        </div>
                        <div class="watchbox-right">
                            <div  style="z-index: 9;position: relative;" class="right-top" >
                                <img src="../assets/img/xieyalogo.png" alt="" v-if="item.onLine == '在线'">
                                <img src="../assets/img/xieyalogono.png" alt="" v-if="item.onLine != '在线'">
                                <div style="margin-left: 0.2rem;">
                                    <div class="right-top-text">
                                        血压(mmHg)  
                                    </div>
                                    <div class="right-top-data" :class="{'home-no-color':item.onLine != '在线'}">
                                        {{ item.pressure.highPressure }}/{{ item.pressure.lowPressure }}&nbsp;(&nbsp;{{ item.pressure.pulse }}&nbsp;)
                                    </div>
                                </div>
                            </div>
                            <div class="right-top-shan" :class="{'right-top-call':item.isPressure == true}">
                            </div>
                            <div class="right-bottom">
                                <div class="right-bottom-left">
                                    <div style="z-index: 9;position: relative;">
                                        <img src="../assets/img/xieyanglogo.png" alt="" v-if="item.onLine == '在线'">
                                        <img src="../assets/img/xieyanglogono.png" alt="" v-if="item.onLine != '在线'">
                                        <div style="margin-left: 0.2rem;">
                                            <div class="right-top-text" style="margin-top: 0.1rem;">
                                                血氧(%)  
                                            </div>
                                            <div class="right-top-data" :class="{'home-no-color':item.onLine != '在线'}">
                                                {{ item.oxygenation }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="shansuobox" :class="{'right-top-call':item.isOxygenation == true}">
                                    </div>
                                </div>
                                <div class="right-bottom-right">
                                    <div style="z-index: 9;position: relative;">
                                        <img src="../assets/img/xinlvlogo.png" alt="" v-if="item.onLine == '在线'">
                                        <img src="../assets/img/xinlvlogono.png" alt="" v-if="item.onLine != '在线'">
                                        <div style="margin-left: 0.2rem;">
                                            <div class="right-top-text" style="margin-top: 0.1rem;">
                                                心率(次/分)  
                                            </div>
                                            <div class="right-top-data" :class="{'home-no-color':item.onLine != '在线'}">
                                                {{ item.heartRate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="shansuobox" :class="{'right-top-call':item.isHeartRate == true}">
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div style="text-align: center;">
            <!-- <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="this.homelogic.searchObj.page"
                :page-size="pagesize"
                layout="total,  prev, pager, next, jumper"
                :total="numTotal"
            >
            </el-pagination> -->
        </div>
    </div>
    <el-dialog
    class="medical-dialog"
    style="height: 9.28rem;width: 8.38rem;"
    :model-value="UserDialog"
    center
    :before-close="nosureParticulars"
    >
        <div class="user-dialog-top">
            <div style="display:flex;font-size:0.18rem;width:100%;height:100%">
                <div style="font-size:0.5rem">{{ info.userName }}</div>
                 <div style="margin-top:0.05rem;" class="left-isOnLineno" v-if="info.onLine != '在线'"></div>
                <div style="margin-top:0.05rem;" class="left-isOnLine" v-if="info.onLine == '在线'"></div>
                <div style="line-height:0.4rem;">
                    <div style="display:flex;height:50%">
                        <span style="margin-left: 0.63rem;">{{ info.genderTypeName }}</span><span style="margin-left: 0.2rem;">•</span><span style="margin-left: 0.2rem;">{{ info.age }}</span><span style="margin-left: 0.2rem;">•</span>
                        <div class="left-electricity-container" style="margin-top: 0.1rem;margin-left: 0.2rem;">
                            <div style="width: 90%;height:85%;margin: auto;margin-top: 4%;">
                                <div class="left-electricity-box" style="height: 102%;" :style="{width: info.battery + '%'}"></div> 
                                <div class="cdings" v-if="info.chargeStatus == 1"></div>
                            </div>
                            <div class="left-electricity-dd"></div>
                        </div>
                        <span style="margin-left: 0.2rem;">{{  info.battery  }}%</span> 
                    </div>
                    <div style="height:50%;">
                        <span style="margin-left:0.63rem;">IMEI序列号：{{ info.imeis }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-dialog-data">
            <div class="dialog-data-top">
                <img src="../assets/img/xieyalogo.png" alt="">
                <div style="margin-left: 0.2rem;">
                    <div class="right-top-text" style="color: #ccc;font-size:0.36rem;margin-top: -0.2rem;">
                        血压(mmHg)  
                    </div>
                    <div class="right-top-data"  style="color: #fff;font-size:0.84rem;" :class="{'home-no-color':info.onLine != '在线'}">
                        {{ info.pressure.highPressure }}/{{ info.pressure.lowPressure }}&nbsp;(&nbsp;{{ info.pressure.pulse }}&nbsp;)
                    </div>
                </div>
            </div>
            <div class="dialog-data-center">
                <div>
                    <img src="../assets/img/xieyanglogo.png" alt="">
                    <div style="margin-left: 0.2rem;">
                        <div class="right-top-text"  style="color: #ccc;font-size:0.36rem;margin-top: -0.2rem;">
                            血氧(%)  
                        </div>
                        <div class="right-top-data" style="color: #fff;font-size:0.84rem;" :class="{'home-no-color':info.onLine != '在线'}">
                            {{ info.oxygenation }}
                        </div>
                    </div>
                </div>
                <div>
                    <img src="../assets/img/xinlvlogo.png" alt="">
                    <div style="margin-left: 0.2rem;">
                        <div class="right-top-text" style="color: #ccc;font-size:0.36rem;margin-top: -0.2rem;" >
                            心率(次/分)  
                        </div>
                        <div class="right-top-data" style="color: #fff;font-size:0.84rem;" :class="{'home-no-color':info.onLine != '在线'}">
                            {{ info.heartRate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-data-bottom">
                <div>
                    <img src="../assets/img/sleep.png" alt="">
                    <div>
                        <div class="right-top-text"  style="color: #ccc;font-size:0.3rem;margin-top: 0.07rem;">
                            睡眠状态  
                        </div>
                        <div class="right-top-data" style="color: #fff;font-size:0.55rem;" :class="{'home-no-color':info.onLine != '在线'}">
                            {{ info.sleepStatusName }}
                        </div>
                    </div>
                </div>
                <div>
                    <img src="../assets/img/call.png" alt="">
                    <div>
                        <div class="right-top-text"  style="color: #ccc;font-size:0.3rem;margin-top: 0.07rem;">
                            SOS  
                        </div>
                        <div class="right-top-data" style="color: #fff;font-size:0.55rem;" :class="{'home-no-color':info.onLine != '在线'}">
                            {{ info.callStatusName }}
                        </div>
                    </div>
                </div>
                <div>
                    <img src="../assets/img/fall.png" alt="">
                    <div>
                        <div class="right-top-text"  style="color: #ccc;font-size:0.3rem;margin-top: 0.07rem;">
                            跌落报警  
                        </div>
                        <div class="right-top-data" style="color: #fff;font-size:0.55rem;" :class="{'home-no-color':info.onLine != '在线'}">
                            {{ info.isFall }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import homeSearch from "@/components/searchComponents/homeSearch.vue";
export default {
    components: {
        homeSearch,
    },
    data(){
        return{
            userlist:[],
            info:[],
            numTotal:'',
            pages:1,
            UserDialog:false,
            handset:null,
            pagesize:6,
            datashow:false,
            page:'',
            informdata:'',
            inform:true,
            totalPage:'',
        }
    },
    mounted(){
        this.handleCurrentChange(0)
        this.handset = setInterval(()=>{
            this.homelogic.searchObj.page = this.pages
            this.homelistgo()
        },20000)
        this.handleScrollMsg()
    },
    unmounted(){
        clearInterval(this.handset)
        this.handset = null
    },
    methods:{
        down(id,num){
            this.homelogic.list.id = id;
            this.homelogic.list.command = num;
            this.homelogic.WatchCommand().then((res) => {
               if(res.flg){
                    this.$message({
                        message: '正在测量',
                        type: 'success'
                    });
                    this.handleCurrentChange(0)
               }
            })
        },
        particulars(item){
            this.info = item
            switch(this.info.isFall){
                case false:
                    this.info.isFall = "否";
                    break;
                case true:
                    this.info.isFall = "是";
                    break;
            }
            this.UserDialog = true
        },
        nosureParticulars(ref) {
         this.$confirm("确认要关闭吗？")
            .then((_) => {
            this.UserDialog = false;
            done();
            })
            .catch((_) => {});
        },
        reset() {
            window.location.reload()
        },
        search() {
           this.handleCurrentChange()
        },
          //横向播放通知公告
        handleScrollMsg(){
            let speed = 30//字体的整体滚动速度
            let box = this.$refs.msgBox
            let msg = this.$refs.scrollMsg
            let scroll = ()=>{
            if (msg.offsetLeft <= (- msg.offsetWidth)) {
                msg.style.left = box.offsetWidth + 'px'
            }else{
                msg.style.left = msg.offsetLeft - 1 + 'px'
            }
        }
        let timer = setInterval(scroll,speed)
        //鼠标移动到通知内容上暂停
        msg.onmouseover = ()=>{
          clearInterval(timer);
        }
        //移开继续滚动
        msg.onmouseout = ()=>{
          timer = setInterval(scroll,speed)
        }
      },
        homelistgo(){

             this.homelogic.gethomeList().then((res) => {
                 if( res.watchPatientList == ''){
                    this.datashow = true
                 }else{
                    this.datashow = false
                    res.watchPatientList.forEach(item=>{
                    switch(item.pressure.highPressure){
                        case 0:
                            item.pressure.highPressure = '---'
                        break;
                    }
                    switch(item.pressure.lowPressure){
                        case 0:
                            item.pressure.lowPressure = '--'
                        break;
                    }
                    switch(item.pressure.pulse){
                        case 0:
                            item.pressure.pulse = '--'
                        break;
                    }
                    switch(item.oxygenation){
                        case 0:
                            item.oxygenation = '--'
                        break;
                    }
                    switch(item.heartRate){
                        case 0:
                            item.heartRate = '--'
                        break;
                    }
                    switch(item.sleepStatusName){
                        case 0:
                            item.sleepStatusName = '--'
                        break;
                    }
                    switch(item.callStatusName){
                        case 0:
                            item.callStatusName = '--'
                        break;
                    }
                    switch(item.sleepStatus){
                        case 0:
                            item.sleepStatus = '--'
                        break;
                    }
                })
                setTimeout(()=>{
                    this.userlist = res.watchPatientList
                    this.totalPage = res.totalPage
                    if(res.patientTestNotes.length != 0){
                        this.informdata = ''
                        res.patientTestNotes.forEach((item) => {
                            this.informdata==""? this.informdata = item: this.informdata += ' ----- '  + item
                            this.inform = true
                            // this.informdata = String(this.informdata)
                        })
                    }else{
                        this.inform = false
                    }
                    this.userlist.forEach((item) => {
                        item.imeis = item.imei
                        item.imeis = item.imeis.replace(
                        /(\w{6})\w*(\w{4})/,
                        "$1********$2"
                        );
                        if(item.age == 0){
                            item.age = ' - '
                        }else{
                            item.age =  item.age + '岁'
                        }
                    });
                    this.numTotal = res.total;
                    console.log(this.totalPage)
                    // if( this.totalPage == 1){
                    //     clearInterval(this.handset)
                    //     this.handset = null
                    // }
                },1000)
                }
                
            });
        },
        handleCurrentChange: function (page = 1) {
            var aa = page + 1
            this.homelogic.searchObj.page = aa
            this.homelistgo()
            // console.log(page)
            this.pages = aa

        },
    }
};
</script>
<style>
 .msg-box{
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
}
.msg{
    position: absolute;
    white-space: nowrap;
}
.el-carousel__item{
    display: flex !important;
    flex-wrap: wrap;
    height: 100%;
}
.el-carousel {
    height: 100%;
}
.el-carousel__container {
    height: 100% !important;
    z-index: 99 !important;
}
.el-carousel__container > .is-active{
    background: none !important;
}
.el-carousel__indicator{
    position: relative;
    top: -0.2rem;
    z-index: 999 !important;
}
</style>
import proxy from '../main'
import axios from 'axios'
export default {
    time :{
        startDate: '2023/01/01',
        // endDate: ''
    },
    type:{
        page: 1,
        testType: 1
    },
    // 统计测量项的异常和正常数量
    PatientStatconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + 'WatchStat/PatientTestStatusStat','',proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    // 统计年月已绑定的手表数量
    WatchStatconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + 'WatchStat/WatchBindStat',this.time,proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    // 设备数量
    WatchnumconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + 'WatchStat/WatchStatusStat','',proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    // 电池剩余电量
    cellconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + 'WatchStat/WatchBatteryTypeStat','',proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    // 年龄段测量项人数
    PatientTypeStatconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + 'WatchStat/PatientTestStatusAgeStat',this.type,proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
}
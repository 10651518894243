<template>
    <div class="medical-list">
        <el-form ref="AddPatient" :model="PatientFrom" :rules="this.patientlogic.rules" class="medical-from">
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;人员名称：</span>
                <el-form-item prop="userName">
                  <div>
                    <el-input
                      v-model="PatientFrom.userName"
                      placeholder="请输入姓名"
                      maxlength="10"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;性&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
                <el-form-item prop="gender">
                  <div>
                    <el-select
                      v-model="PatientFrom.gender"
                      placeholder="请选择性别"
                      class="admin_tel input77"
                    >
                      <el-option
                        v-for="item in this.patientlogic.TF"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;出生日期：</span>
                <el-form-item prop="birthday">
                  <div>
                    <el-date-picker
                    alue-format="YYYY-MM-DD"
                    v-model="PatientFrom.birthday"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;身份证号：</span>
                <el-form-item prop="idCard">
                  <div>
                   
                    <el-input
                        v-model="PatientFrom.idCard"
                        placeholder="请输入身份证号"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
           <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;身&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高：</span>
                <el-form-item prop="height">
                  <div>
                    <el-input
                        v-model="PatientFrom.height"
                        placeholder="请输入身高(cm)"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;体&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重：</span>
                <el-form-item prop="weight">
                  <div>
                    <el-input
                        v-model="PatientFrom.weight"
                        placeholder="请输入体重(kg)"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
            style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="20">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;社保卡号：</span>
                <el-form-item prop="ssid">
                  <div>
                    <el-input
                      v-model="PatientFrom.ssid"
                      placeholder="请输入社保卡号"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureAddPatient('AddPatient')" type="info"
            ></el-button
          >
          <el-button class="qd" @click="sureAddPatient('AddPatient')" type="primary"
            ></el-button
          >
        </div>
      </div>
   </template>
   <script>
   export default {
     name: "add",
     data() {
       return {
         PatientFrom: {},
         userList: [],
       };
     },
     mounted() {  
     },
     methods: {
       
       sureAddPatient(ref) {
        var date = new Date().getTime();
        if(this.PatientFrom.birthday != undefined){
          var aa = this.PatientFrom.birthday.getTime();
          if(date > aa){
            if(this.PatientFrom.height != 0 && this.PatientFrom.weight != 0 && this.PatientFrom.ssid != 0 ){
               this.$refs[ref].validate((valid) => {
                  if (valid) {
                      this.axios
                      .post(this.baseURL + "Patient/PatientAdd", this.PatientFrom, this.headers())
                      .then((res) => {
                        this.resolveData(res.data, "add");
                      });
                    this.addPatientFrom = false;
                  } else {
                    return false;
                  }
                });
            }else{
              this.$message({
                message: `身高或体重不能为0`,
                type: "warning",
            });
            }
          }else{
            this.$message({
                message: `出生日期不能大于当前时间`,
                type: "warning",
            });
          }
        }else{
          this.$message({
              message: `请填写所有必填项`,
              type: "warning",
          });
        }
       },
       nosureAddPatient() {
         this.$confirm("确认要关闭吗？")
           .then((_) => {
             done();
           })
           .catch((_) => {});
       },
     },
   };
   </script>
   
   
   
<template>
    <div class="activate-top"></div>
    <div class="activate-bottom">
        <div class="activate-search-top">
            <div class="block">
                <el-date-picker
                v-model="value1"
                type="daterange"
                value-format="YYYY-MM-DD"
                @change="datechange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
                </el-date-picker>
            </div>
        </div>
        <div id="activate-echarts"></div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            value1:''
        }
    },
    mounted(){
        this.charts.activate()
    },
    methods:{
        datechange(e){
            this.chartslogic.time.startDate = e[0]
            this.chartslogic.time.endDate = e[1]
            this.charts.activate()
        }   
    }
}
</script>
<style>

</style>
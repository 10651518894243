<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <deviceSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></deviceSearch>
                  </div>
                  <devicelist
                  class="customer-table"
                  :header-cell-style="{ 
                  background:'#0A1634',color:'#fff'}"
                  :tableHeader="this.devicelogic.opHeader()"
                  :tableData="deviceList"
                  :page="this.devicelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="primary" @click="bdPhones(slotProps.row)">
                              绑定手机号
                          </el-button>
                      </template>
                  </devicelist>
                  <pager
                  :page="this.devicelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
      <el-dialog
        class="medical-dialogs edit-dialog"
        :model-value="bdPhoneFrom"
        center
        :before-close="nosureBdPhone"
      >
      <div class="dialog-titile">绑定手机号</div>
        <el-form ref="bdPhone" :model="phoneFrom" :rules="this.devicelogic.rules" class="medical-from" style="margin-top: 2rem;">
        <el-row
                style="display: flex; flex-wrap: wrap; justify-content: center;margin-top: 0.5rem"
            >
                <el-col :span="24" :md="20">
                <div class="addform-box">
                    &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;手&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;号：</span>
                    <el-form-item prop="phone">
                        <div>
                            <el-input
                                v-model="phoneFrom.phone"
                                placeholder="请输入手机号"
                            >
                            </el-input>
                        </div>
                    </el-form-item>
                </div>
                </el-col>
            </el-row>
        </el-form>
        <div class="sureBtn" style="margin-top: 2.5rem;">
          <el-button class="qx" @click="nosureBdPhone('bdPhone')" type="info"
            ></el-button
          >
          <el-button class="qd" @click="sureAddPhone('bdPhone')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
  </template>
  
  <script>
  import basecom from "@/components/base";
  import devicelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
  export default {
    components: {
        devicelist,
        pager,
        deviceSearch,
        top,
        basecom
    },
    data() {
      return {
        bdPhoneFrom: false,
        deviceList: [],
        page: 1,
        numTotal: 0,
        phoneFrom:{}
      };
    },
    mounted() {
      this.search();
    },
    methods: {
        sureAddPhone(ref){
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    this.axios
                    .post(this.baseURL + "Watch/WatchEdit", this.phoneFrom, this.headers())
                    .then((res) => {
                        this.resolveData(res.data, "binding");
                    });
                } else {
                    return false;
                }
            });
        },
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.devicelogic.searchObj.page = page;
        this.devicelogic.getWatchList().then((res) => {
            res.watchList.forEach(item=>{
              item.battery = item.battery + '%'
            })
            this.deviceList = res.watchList;
            this.numTotal = res.total;
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      nosureBdPhone() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.bdPhoneFrom = false;
            done();
          })
          .catch((_) => {});
      },
      bdPhones(row) {
        this.phoneFrom.id = row.id
        this.bdPhoneFrom = true;
        let obj = { ...row };
        this.phoneFrom = obj;
        this.resetForm("bdPhone");
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
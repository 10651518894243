<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                    <patientSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></patientSearch>
                    </div>
                    <patientlist
                    class="customer-table"
                    :header-cell-style="{ 
                    background:'#0A1634',color:'#fff'}"
                    :tableHeader="this.patientlogic.listHeader()"
                    :tableData="patientList"
                    :page="this.patientlogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    ></patientlist>
                    <pager
                    :page="this.patientlogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import patientlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import patientSearch from "@/components/searchComponents/patientSearch.vue";
export default {
    components: {
        basecom,
        patientlist,
        pager,
        patientSearch,
        top
    },
    data() {
        return {
            patientList: [],
            page: 1,
            numTotal: 0,
        };
    },
    mounted() {
        this.handleCurrentChange();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.patientlogic.searchObj.page = page;
            this.patientlogic.getPatientList().then((res) => {
                res.patientList.forEach(item=>{
                    item.height = parseFloat(item.height).toFixed(2)
                    item.weight = parseFloat(item.weight).toFixed(2)
                    item.heights = item.height + '          '  + 'cm'
                    item.weights = item.weight + '          '  + 'kg'
                    item.Cardid = item.idCard
                    item.Cardid = item.Cardid.replace(/(\w{6})\w*(\w{4})/, "$1********$2")
                })  
               
                this.patientList = res.patientList;
                this.numTotal = res.total;
            });
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
    },
    };
</script>
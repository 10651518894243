<template>
    <div class="medical-list">
        <agencyList
            class="customer-table"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ 
            background:'#0A1634',color:'#fff'}"
            :tableData="this.agencylogic.loadagencyList()"
            :tableHeader="this.agencylogic.opHeader()"
            :lazyLoad="this.agencylogic.getChildAgencyList"
        >
            <template v-slot:btn="slotProps">
              <el-button type="primary" @click.stop="addagency(slotProps.row)">
                  添加子机构
              </el-button>
            </template>
        </agencyList>
    </div>
      <el-dialog
        class="medical-dialogs"
        :model-value="addAgencyDial"
        center
        :before-close="nosureAddAgency"
      >
      <div class="dialog-titile">添加子机构</div>
        <div class="xgmm_form" style="margin-top: 5%;">
            <el-form
              ref="addagencyForm"
              :model="agencyForm"
              style="height: 5rem;overflow-y: auto;overflow-x: hidden;"
              :rules="this.agencylogic.rules"
            > <el-row
               style="display: flex; flex-wrap: wrap;"
           >
             <el-col>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构名称：</span>
                  <el-form-item prop="agencyName">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构名称"
                      v-model="agencyForm.agencyName"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构编码：</span>
                  <el-form-item prop="agencyCode">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构编码"
                      v-model="agencyForm.agencyCode"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;机构所在经度：</span>
                  <el-form-item prop="longitude">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构所在经度"
                      v-model="agencyForm.longitude"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;机构所在纬度：</span>
                  <el-form-item prop="latitude">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构所在纬度"
                      v-model="agencyForm.latitude"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构地址：</span>
                  <el-form-item prop="address">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构地址"
                      v-model="agencyForm.address"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构特点：</span>
                  <el-form-item prop="special">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构特点"
                      v-model="agencyForm.special"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="sureBtn">
            <el-button class="dw" @click="position()" type="warning"
                >精准定位</el-button
            >
            <el-button class="qx" @click="nosureAddAgency('addagencyForm')" type="info"
                ></el-button
            >
            <el-button class="qd" @click="sureAddAgency('addagencyForm')" type="primary"
                ></el-button
            >
        </div>
      </el-dialog>
  </template>
  
  
  <script>
  import agencyList from "@/components/listComponents/treeList";
  export default {
    name: "add",
    components: {
      agencyList,
    },
    data() {
      return {
        addAgencyDial: false,
        roleName: "",
        agencyForm: {
            agencyName:'',
            agencyCode:'',
            longitude:'',
            latitude:'',
            address:'',
            special:'',
            agencyParentId:''
        },
      };
    },
    methods: {
        position() {
            let jwd = window.localStorage;
            jwd.lon = "";
            jwd.lat = "";
            let routeUrl = this.$router.resolve({
                path: "/mapDialogVisible.html",
            });
            window.open(routeUrl.href, "_blank");
            localStorage.setItem("isClosemap", false);
            window.addEventListener('storage',this.setinfo)
        },
        setinfo(e){
            if(e.storageArea.isClosemap === 'true' && e.storageArea.lon !== '0'  && e.storageArea.lat !== '0'){
                let lon = "";
                let lat = "";
                lon = window.localStorage.getItem("lon");
                lat = window.localStorage.getItem("lat");
                if (lon === window.localStorage.getItem("lon")) {
                if (lon !== "") {
                    this.agencyForm.longitude = lon;
                    this.agencyForm.latitude = lat;
                    window.localStorage.setItem("lon", 0);
                    window.localStorage.setItem("lat", 0);
                }
                }
            }
        },
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      sureAddAgency(ref) {
        this.$refs[ref].validate((valid) => {
        console.log(  this.$refs)
          if (valid) {
            this.axios
              .post(this.baseURL + "Agency/AddAgency", this.agencyForm, this.headers())
              .then((res) => {
                this.resolveData(res.data, "add");
                this.addAgencyDial = false;
              });
          } else {
            return false;
          }
        });
      },
      nosureAddAgency() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.addAgencyDial = false;
            done();
          })
          .catch((_) => {});
      },
  
      addagency(row) {
        this.agencyForm.agencyParentId = row.id
        this.addAgencyDial = true;
        this.resetForm("addagencyForm");
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
    },
  };
  </script>
<template>
    <div class="body">
         <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <home />
            </div>
        </div>
    </div>
</template>

<script>
import basecom from "@/components/base.vue";
import top from "@/components/top";
import home from "@/components/home";
export default {
    components: { basecom,top,home },
    mounted(){
    }
};
</script>

<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <add></add>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import add from "@/components/funcComponents/add";
export default { 
    components: {
        basecom,
        add,
        top
    },
};
</script>
  
  
  
  
  
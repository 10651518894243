<template>
    <div class="body">
      <div id="container"></div>
      <!--退出弹窗-->
      <div class="mask_box"></div>
      <div class="exit_box">
        <img src="../assets/img/exit_img.png" @click="exit_btn" />
        <p>当前位置</p>
        <p>经度:<span class="LngValue"></span></p>
        <p>纬度:<span class="latValue"></span></p>
        <p>是否使用当前坐标？</p>
        <div class="btn">
          <el-button class="qx" type="waring" @click="exit_btn">取消</el-button>
          <el-button class="qd" type="success" @click="confirm_btn"
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </template>
     
     <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  export default {
    data() {
      return {
        map: null,
        lon: localStorage.getItem("lon") || 0,
        lat: localStorage.getItem("lat") || 0,
        message:  localStorage.getItem("message") || ''
      };
    },
    mounted() {
      this.initMap();
    },
    methods: {
      initMap() {
        AMapLoader.load({
          key: "3ad45ee63b58534033aadf48366a6ee1", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ["AMap.ToolBar"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              resizeEnable: true,
            });
            const that = this
            this.map.on("click", function (e) {
              // document.getElementById("lnglat").value = e.lnglat.getLng() + ',' + e.lnglat.getLat()
              let lon = e.lnglat.getLng();
              let lat = e.lnglat.getLat();
              that.lon = e.lnglat.getLng();
              that.lat = e.lnglat.getLat();
              let obj;
              AMap.plugin("AMap.Geocoder", function () {
                var geocoder = new AMap.Geocoder({
                  // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                  city: "",
                });
                var lnglat = [lon, lat];
                geocoder.getAddress(lnglat, function (status, result) {
                  if (status === "complete" && result.info === "OK") {
                    let message = result;
                    obj = {
                      formattedAddress: message.regeocode.formattedAddress,
                      adcode: message.regeocode.addressComponent.adcode,
                      citycode: message.regeocode.addressComponent.citycode,
                      province: message.regeocode.addressComponent.province,
                      city:
                        message.regeocode.addressComponent.city == "" ||
                        message.regeocode.addressComponent.city == null
                          ? message.regeocode.addressComponent.province.indexOf(
                              "市" != -1
                            )
                            ? message.regeocode.addressComponent.province
                            : message.regeocode.addressComponent.district
                          : message.regeocode.addressComponent.city,
                      district:
                        message.regeocode.addressComponent.district == "" ||
                        message.regeocode.addressComponent.district == null
                          ? message.regeocode.addressComponent.city
                          : message.regeocode.addressComponent.district,
                    };
                    let areas = [
                      obj.province,
                      obj.province + obj.city,
                      obj.province + obj.city + obj.district,
                    ];
                    geocoder.getLocation(areas, (status, result) => {
                      if (status === "complete" && result.geocodes.length) {
                        for (var i = 0; i < result.geocodes.length; i += 1) {
                          let location = result.geocodes[i].location;
                          switch (i) {
                            case 0:
                              obj.provinceLongitude = location.lng;
                              obj.provinceLatitude = location.lat;
                              break;
                            case 1:
                              obj.cityLongitude = location.lng;
                              obj.cityLatitude = location.lat;
                              break;
                            case 2:
                              obj.districtLongitude = location.lng;
                              obj.districtLatitude = location.lat;
                              break;
                          }
                        }
                      }
                      that.message = JSON.stringify(obj)
                      window.localStorage.message = JSON.stringify(obj);
                    });
                  }
                });
              });
  
              //添加管辖设备add
              document.querySelector(".latValue").innerHTML = e.lnglat.getLat();
              document.querySelector(".LngValue").innerHTML = e.lnglat.getLng();
              document.querySelector(".mask_box").style.display = "block";
              document.querySelector(".exit_box").style.display = "block";
            });
          })
          .catch((e) => {});
      },
  
      select(e) {
        if (e.poi && e.poi.location) {
          map.setZoom(15);
          map.setCenter(e.poi.location);
        }
      },
      getPointAddress(lon, lat) {},
      exit_btn() {
        document.querySelector(".mask_box").style.display = "none";
        document.querySelector(".exit_box").style.display = "none";
      },
  
      confirm_btn() {
        localStorage.setItem("lon", this.lon);
        localStorage.setItem("lat", this.lat);
        localStorage.setItem("message", this.message);
        localStorage.setItem("isClosemap", true);
        document.querySelector(".mask_box").style.display = "none";
        document.querySelector(".exit_box").style.display = "none";
        window.close();
      },
    },
  };
  </script>
     
    
  <style  scoped>
  #container {
    padding: 0px;
    margin: 0px;
    width: 100%;
  
    height: 100%;
    position: absolute;
  }
  </style>
  
  
  
  <style src="@/assets/css/mapDialog.css" scoped></style>
    
     
     
import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import router from './router'
import 'element-plus/dist/index.css'
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as echarts from 'echarts'
import 'echarts-gl';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import loginLogic from '@/logic/loginlogic'
import userlogic from '@/logic/userlogic'
import rolelogic from '@/logic/rolelogic'
import funlogic from '@/logic/funlogic'
import patientlogic from '@/logic/patientlogic'
import devicelogic from '@/logic/devicelogic'
import homelogic from '@/logic/homelogic'
import charts from '@/logic/charts'
import abnormallogic from '@/logic/abnormallogic'
import configlogic from '@/logic/configlogic'
import chartslogic from '@/logic/chartslogic'
import agencylogic from '@/logic/agencylogic'
import VueTouch from "vue-touch";
// import 'swiper/css/swiper.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'


const app = createApp(App)
app.use(VueAxios, axios)
// app.use(VueAwesomeSwiper)
app.use(router)
app.use(VueTouch, {name: "v-touch"})
app.use(ElementPlus, {
    locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.SystemShortTitle = "后台系统"

app.config.globalProperties.echarts = echarts;
app.config.globalProperties.loginLogic = loginLogic;
app.config.globalProperties.userlogic = userlogic;
app.config.globalProperties.rolelogic = rolelogic;
app.config.globalProperties.funlogic = funlogic;
app.config.globalProperties.patientlogic = patientlogic;
app.config.globalProperties.devicelogic = devicelogic;
app.config.globalProperties.homelogic = homelogic;
app.config.globalProperties.charts = charts;
app.config.globalProperties.abnormallogic = abnormallogic;
app.config.globalProperties.configlogic = configlogic;
app.config.globalProperties.chartslogic = chartslogic;
app.config.globalProperties.agencylogic = agencylogic;
// app.config.globalProperties.baseURL = "http://192.168.0.188:92/";
// app.config.globalProperties.baseURL = "http://39.106.209.158:5012/";
app.config.globalProperties.baseURL = "https://watchapi.mbelec.com/";
app.config.globalProperties.emptyId = "00000000-0000-0000-0000-000000000000";
app.config.globalProperties.needLogin = false;
app.config.globalProperties.PageSize = 10;

app.config.globalProperties.maxFileSize = 25 * 1024 * 1024;
app.config.globalProperties.token = function () { return sessionStorage.getItem("token") }
app.config.globalProperties.header = function () { return { token: this.token() }; }
app.config.globalProperties.headers = function () { return { headers: this.header() }; }
app.config.globalProperties.session = function (item) { return sessionStorage.getItem(item); }

// element plus 报错处理
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
app.config.globalProperties.getFunction = function () {
    let fl = JSON.parse(this.session("functionList"))
    let fo = fl.filter(element => element.fpid === this.emptyId);
    if (fo != null) {
        fl = fo
    } else {
        fl = this.functiontoTree(fl)
    }
    return fl
}
app.config.globalProperties.functiontoTree = function (data) {
    let result = []
    if (!Array.isArray(data)) {
        return result
    }
    data.forEach(item => {
        delete item.children;
    });
    let map = {};
    data.forEach(item => {
        map[item.id] = item;
    });
    data.forEach(item => {
        let parent = map[item.fpid];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            result.push(item);
        }
    });
    return result;
}
app.config.globalProperties.getRole = function () {
    let rlstr = this.session("roleList")
    if (rlstr != null && rlstr != undefined) {
        let rl = JSON.parse(rlstr)
        let ro = rl.find(element => element.rpid === this.emptyId);
        if (ro != null) {
            rl = []
            rl.push(ro)
        } else {
            rl = this.roletoTree(rl)
        }
        return rl
    } else
        return null
}
app.config.globalProperties.getAgency = function () {

    let rlstr = this.session("agencyList")
    if (rlstr != null && rlstr != undefined) {
        let rl = JSON.parse(rlstr)
        let ro = rl.find(element => element.rpid === this.emptyId);
        if (ro != null) {
            rl = []
            rl.push(ro)
        } else {

        }
        return rl
    } else
        return null
}
app.config.globalProperties.roletoTree = function (data) {
    let result = []
    if (!Array.isArray(data)) {
        return result
    }
    data.forEach(item => {
        delete item.children;
    });
    let map = {};
    data.forEach(item => {
        map[item.id] = item;
    });
    data.forEach(item => {
        let parent = map[item.rpid];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            result.push(item);
        }
    });
    return result;
}
app.config.globalProperties.getRoleText = function () {
    let rt = "";
    let rl = this.getRole();
    if (rl != null) {
        rl.forEach(item => { rt += item.roleName });
    }
    return rt
}
function selfAdaption() {
    if (window.screen.width > 1280) {
        let windowWidthPer = window.screen.width / 1920;
        document.body.style.zoom = windowWidthPer
    }
}
selfAdaption()
app.config.globalProperties.getAgency = function () {

    let rlstr = this.session("agencyList")
    if (rlstr != null && rlstr != undefined) {
        let rl = JSON.parse(rlstr)
        let ro = rl.find(element => element.rpid === this.emptyId);
        if (ro != null) {
            rl = []
            rl.push(ro)
        } else {

        }
        return rl
    } else
        return null
}
app.config.globalProperties.getAgencyText = function () {
    let rt = "";
    let rl = this.getAgency();
    if (rl != null) {
        rl.forEach(item => { rt += item.agencyName });
    }
    return rt
}
app.config.globalProperties.sortByKey = function (array, key) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x < y ? 1 : 0;
    });
};
app.config.globalProperties.resolveData = function (res, option = "data") {
    if (res.code === 103 || res.code === 100) {
        if (this.needLogin == false) this.needLogin = true;
        if (this.needLogin) {
            this.$message({
                message: res.msg,
                type: "error",
            });
            setTimeout(() => {
                this.needLogin = false;
                this.$router.push("/login.html");
            }, 1500);
        }
    } else {
        if (res.code === 200) {
            let msg = "";
            switch (option) {
                case "add":
                    msg = "添加成功";
                    break;
                case "edit":
                    msg = "修改成功";
                    break;
                case "assign":
                    msg = "分配成功";
                    break;
                case "delete":
                    msg = "删除成功";
                    break;
                case "logout":
                    msg = "退出成功";
                    break;
                case "binding":
                    msg = "绑定成功";
                    break;
            }
            if (msg != "") {
                this.$message({
                    message: msg,
                    type: "success",
                });
                if (msg == "退出成功") {
                    setTimeout(() => {
                        this.$router.push("/login.html");
                    }, 1500);
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
            return res.data;
        } else {
            this.$message({
                message: res.msg,
                type: "error",
            });
            return null;
        }
    }
};
router.beforeEach((to, from, next) => {
    next();
});





let reqList = []

function stopRepeatRequest(reqList, url, cancel, errorMessage) {
    if(url.indexOf("WatchStat/PatientTestStatusAgeStat") > -1 || url.indexOf("Agency/AgencyList") > -1  || url.indexOf("Index/WatchPatientList") > -1){
        return
    }
    const errorMsg = errorMessage || ''
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            cancel(errorMsg)
            return
        }
    }
    reqList.push(url)
}
function allowRequest(reqList, url) {
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            reqList.splice(i, 1)
            break
        }
    }
}

axios.interceptors.request.use((config) => {
    if (config.url.indexOf("Index/WatchPatientList") == -1)
        showFullScreenLoading()
    let cancel
    config.cancelToken = new axios.CancelToken(c => cancel = c)
    stopRepeatRequest(reqList, config.url, cancel, `${config.url} 请求被中断`)
    return Promise.resolve(config)
}, (error) => {
    return Promise.reject(error)
});
axios.interceptors.response.use((res) => {
    setTimeout(() => {
        tryHideFullScreenLoading()
        allowRequest(reqList, res.config.url)
    }, 300)
    return Promise.resolve(res)

}, (error) => {

    if (axios.isCancel(error)) {

    } else {
        allowRequest(reqList, error.config.url)
    }
    return Promise.reject(error)
})

let needLoadingRequestCount = 0

function showFullScreenLoading() {

    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

function tryHideFullScreenLoading() {

    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }

}
let loading

function startLoading() {

    var dialog = document.querySelector(".el-dialog")
    loading = app.config.globalProperties.$loading({
        target: dialog != undefined || dialog != null ? dialog : document.querySelector(".body"),
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}
function endLoading() {
    loading.close()
}
function selfAdaptionye() {
    if (window.screen.width > 1280) {
        let windowWidthPer = window.screen.width / 1920;
        document.body.style.zoom = windowWidthPer
    }
}
selfAdaptionye()
const proxy = app.mount("#app")
export default proxy
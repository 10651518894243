<template>
  <div class="body">
      <div class="top-box">
          <top />
      </div>
      <div class="bottom-box">
          <basecom />
          <div class="list">
              <div class="medical-list">
                  <div class="search">
                  <deviceSearch
                  :search="search"
                  :reset="reset"
                  :bindingagency="bindingagency"
                  :numTotal="numTotal"
                  ></deviceSearch>
                  </div>
                  <watchList
                  ref="multipleSelection"
                  class="customer-table"
                  :header-cell-style="{ 
                  background:'#0A1634',color:'#fff'}"
                  :tableHeader="this.devicelogic.listHeader()"
                  :tableData="watchList"
                  :page="this.devicelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  tooltip-effect="dark"
                  @selection-change="handleSelectionChange"
                  :needselect="true"
                  ></watchList>
                  <pager
                  :page="this.devicelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
              </div>
          </div>
      </div>
  </div>
  <el-dialog
        class="medical-dialogs edit-dialog"
        :model-value="bdagencytable"
        center
        :before-close="nosureBdPhone"
      >
      <agencyList
      class="customer-table"
      :row-class-name="tableRowClassName"
      :header-cell-style="{ 
      background:'#0A1634',color:'#fff'}"
      :tableData="this.agencylogic.loadagencyList()"
      :tableHeader="this.agencylogic.opHeader()"
      :lazyLoad="this.agencylogic.getChildAgencyList"
      >
        <template v-slot:btn="slotProps">
          <el-button type="primary" @click.stop="bdagency(slotProps.row)">
              绑定机构
          </el-button>
        </template>
      </agencyList>
  </el-dialog>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import agencyList from "@/components/listComponents/treeList";
import watchList from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
export default {
  components: {
      basecom,
      watchList,
      pager,
      deviceSearch,
      top,
      agencyList
  },
  data() {
      return {
        watchList: [],
        page: 1,
        numTotal: 0,
        multipleSelection:[],
        bdagencytable:false,
      };
  },
  mounted() {
      this.handleCurrentChange();
  },
  methods: {
      bdagency(row){
        var aa = {
          watchIdList:this.multipleSelection,
          agencyId: row.id
        }
        this.axios.post(this.baseURL + "Watch/WatchBindAgency", aa, this.headers()).then(res=>{
            this.resolveData(res.data, "binding");
        })
      },
      handleSelectionChange(val) {
          this.multipleSelection = []
          val.forEach(item => {
              const id = item.id
              if (this.multipleSelection.indexOf(id) == -1) {
                  this.multipleSelection.push(id)
              }
          })
          console.log(this.multipleSelection)
      },
      tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
      },
      handleCurrentChange: function (page = 1) {
          this.devicelogic.searchObj.page = page;
          this.devicelogic.getWatchList().then((res) => {
              res.watchList.forEach(item=>{
                  item.battery = item.battery + '%'
              })
              this.watchList = res.watchList;
              this.numTotal = res.total;
          });
      },
      bindingagency(){
          if (this.multipleSelection.length == 0)
          this.$message({
              message: "未选择设备",
              type: "error",
          });
          else
          this.$confirm("确认要为选中的设备绑定机构吗？")
              .then((_) => {
                  this.bdagencytable = true
          })
          .catch((_) => {});
          
      },
      nosureBdPhone() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.bdagencytable = false;
            done();
          })
          .catch((_) => {});
      },
      reset() {
          window.location.reload()
      },
      search() {
          this.handleCurrentChange();
      },
  },
  };
</script>
import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        testType: 1,
    },
    page:1,
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "configTypeName", label: "类型"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:120 });
        return header;
    },
    getconfigList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Config/ConfigList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data))
                })
        });
    },
    reset: function () {
        this.searchObj = {  page: 1, testType: 1, };
    }
}
<template>
    <div class="exportExcel">
      <el-row :gutter="20" class="export" style="margin-left: 0.25rem;">
        <el-col :span="2.5" class="title">导出设置：</el-col>
        <el-col :span="2">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            label="allProp"
            class="allPropChange"
            @change="checkAllPropChange"
          >
            <span class="text">全部字段</span>
          </el-checkbox>
        </el-col>
        <el-col :span="2">
          <el-checkbox
            label="allData"
            class="allPropChange"
            @change="checkAllDataChange"
          >
            <span class="text">全部数据</span>
          </el-checkbox></el-col
        >
        <el-col :span="3" class="title">Excel加密设置：</el-col>
        <el-col :span="3" class="inputEx">
          <el-input
            class="admin_tel input77"
            v-model="pwd"
            maxlength="10"
            placeholder="请输入加密Excel的密码"
            @change="pwdChange"
          />
        </el-col>
        <el-col :span="2.5">
          <el-button @click="excelClick" type="success" style="margin-left: 1rem;margin-bottom: 1rem;">Excel表头设置</el-button>
        </el-col>
        <el-col :span="2.5">
          <el-button type="primary" @click.stop="exportData">导出</el-button>
        </el-col>
      </el-row>
      <el-row>
        <div class="excelTitle" style="display: none;margin-left: 0.35rem;">
          <el-checkbox-group v-model="checkedFields" @change="checkedFieldChange">
            <el-col
              :key="field.key"
              v-for="field in fields"
              :sm="6"
              :xs="8"
              :xl="4"
            >
              <el-checkbox :label="field.key" :key="field.key">
                <span class="text">{{ field.value }}</span>
              </el-checkbox>
            </el-col>
          </el-checkbox-group>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    name: "field",
    props: {
      fields: {
        type: Array,
        default: function () {
          return [];
        },
      },
      fieldKeys: {
        type: Array,
        default: function () {
          return [];
        },
      },
      exportData: {
        type: Function,
        default: function () {},
      },
    },
    data() {
      return {
        ExcelBox: false,
        checkAll: false,
        checkedFields: this.devicelogic.selectedProps,
        isIndeterminate: false,
        pwd: "123456",
      };
    },
    mounted() {
      let allPropChange = document.querySelector(".allPropChange");
      allPropChange.click();
      this.devicelogic.pwd = this.pwd;
    },
    methods: {
      excelClick() {
        let excelTitle = document.querySelector(".excelTitle");
        if (excelTitle.style.display === "none") {
          excelTitle.style.display = "block";
        } else {
          excelTitle.style.display = "none";
        }
      },
      checkAllPropChange(val) {
        this.checkedFields = this.devicelogic.selectedProps = val
          ? this.fieldKeys
          : [];
  
        this.isIndeterminate = false;
        this.devicelogic.allProp = val;
      },
      checkAllDataChange(val) {
        this.devicelogic.allData = val;
      },
      checkedFieldChange(value) {
        this.devicelogic.selectedProps = value;
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.fields.length;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.fields.length;
      },
      pwdChange(val) {
        this.devicelogic.pwd = val;
      },
    },
  };
  </script>
  <style scoped>
  .exportExcel{
      font-size: 0.2rem;
      color: #fff;
  }
  .export{
      height: 0.5rem;
  }
  .el-checkbox-group{
      display: flex;
  }
  </style>
  
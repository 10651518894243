<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                        <patientSearch
                        :search="search"
                        :reset="reset"
                        :numTotal="numTotal"
                        ></patientSearch>
                    </div>
                    <patientList
                    class="customer-table"
                    :header-cell-style="{ 
                    background:'#0A1634',color:'#fff'}"
                    :tableHeader="this.patientlogic.opHeader()"
                    :tableData="PatientList"
                    :page="this.patientlogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    >
                    <template v-slot:btn="slotProps">
                        <el-button type="primary" @click="measurePatient(slotProps.row)">
                            测量记录
                        </el-button>
                        </template>
                    </patientList>
                    <pager
                    :page="this.patientlogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
        </div>
    </div>
    <el-dialog
    class="medical-dialogs edit-dialog"
    :model-value="editPatientFrom"
    center
    :before-close="nosureEditPatient"
    >
        <div class="dialog-titile" >测量记录</div>
        <div class="search">
            <measureSearch
            :search="searchmeasure"
            :reset="resetmeasure"
            :numTotal="numTotalmeasure"
            ></measureSearch>
        </div>
        <patientmeasureList
        style="margin-top: 1%;margin-bottom: 2%;"
        class="customer-table"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableHeader="this.patientlogic.MeasurelistHeader()"
        :tableData="patientListMeasure"
        :page="this.patientlogic.measureSearchObj.page"
        :row-class-name="tableRowClassName"
        >
            <template v-slot:btn="slotProps">
                <el-button type="primary" @click="bindingPatient(slotProps.row)">
                    绑定人员
                </el-button>
            </template>
        </patientmeasureList>
        <pagermeasure
        :page="this.patientlogic.measureSearchObj.page"
        :numTotal="numTotalmeasure"
        :handleCurrentChange="handleCurrentChangemeasure"
        >
        </pagermeasure>
    </el-dialog>
</template>
  
<script>
import basecom from "@/components/base";
import patientList from "@/components/listComponents/pagerList";
import patientmeasureList from "@/components/listComponents/pagerList";
import top from "@/components/top.vue";
import pager from "@/components/listComponents/pager.vue";
import pagermeasure from "@/components/listComponents/pager.vue";
import patientSearch from "@/components/searchComponents/patientSearch.vue";
import measureSearch from "@/components/searchComponents/measureSearch.vue";
export default {
components: {
    patientList,
    pager,
    patientSearch,
    top,
    basecom,
    patientmeasureList,
    pagermeasure,
    measureSearch
},
data() {
    return {
        editPatientFrom: false,
        PatientFrom: {},
        PatientList: [],
        patientListMeasure:[],
        page: 1,
        numTotal: 0,
        TF: [
            { value: false, label: "否" },
            { value: true, label: "是" },
        ],
        numTotalmeasure:0,
        patientId:'',
    };
},
mounted() {
    this.search();
},
methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
    },
    handleCurrentChange: function (page, userName, email, mobile) {
        this.patientlogic.searchObj.page = page;
        this.patientlogic.getPatientList().then((res) => {
        res.patientList.forEach(item=>{
            item.height = parseFloat(item.height).toFixed(2)
            item.weight = parseFloat(item.weight).toFixed(2)
            item.Cardid = item.idCard
            item.Cardid = item.Cardid.replace(/(\w{6})\w*(\w{4})/, "$1********$2")
            item.heights = item.height + '          '  + 'cm'
            item.weights = item.weight + '          '  + 'kg'
        })
        this.PatientList = res.patientList;
        this.numTotal = res.total;
    });
    },
    nosureEditPatient() {
        this.$confirm("确认要关闭吗？")
        .then((_) => {
            this.editPatientFrom = false;
            done();
        })
        .catch((_) => {});
    },
    measurePatient(row) {
        this.patientId = row.id
        this.editPatientFrom = true;
        this.handleCurrentChangemeasure()
        this.patientlogic.measureSearchObj.page = 1
    },
    handleCurrentChangemeasure(page){
        this.patientlogic.measureSearchObj.patientId = this.patientId ;
        this.patientlogic.measureSearchObj.page = page;
        this.patientlogic.getMeasureList().then((res) => {
            this.patientListMeasure = res.testRecordList
            this.numTotalmeasure = res.total;
        })
    },
    reset() {
        window.location.reload()
        this.handleCurrentChange();
    },
    resetmeasure(){
        this.patientlogic.measureSearchObj.testType = -99;
        this.handleCurrentChangemeasure()
    },
    searchmeasure(){
        this.handleCurrentChangemeasure()
    },
    search() {
        this.handleCurrentChange();
    },
},
};
</script>
  
<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
              设备列表·共&nbsp;<span style="color: #FFA70D;font-size: 0.25rem;">{{ numTotal }}</span>&nbsp;台
            </div>
          </el-col>
        </div>
        <div class="searchlist">
          &nbsp;&nbsp;
          <el-col :span="4.5">
          </el-col>
          <el-col :span="2.5">
            <el-input
              v-model="imei"
              placeholder="请输入设备序列号"
              @change="setimei"
              @keyup.enter="search"
            >
            </el-input>
          </el-col>
          <el-col :span="4.8" style="margin-right:0.15rem">
            <el-select
                v-model="chargeStatus"
                placeholder="请选择充电状态"
                class="admin_tel input77"
                @change="setstatus"
              >
                <el-option
                  v-for="item in this.devicelogic.TF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="4.8" style="margin-right:0.15rem">
            <el-select
                v-model="watchStatus"
                placeholder="请选择在线状态"
                class="admin_tel input77"
                @change="watchstatus"
              >
                <el-option
                  v-for="item in this.devicelogic.TE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="1.5" class="col-cascader">
           <el-cascader
              v-model="id"
              :options="options"
              class="admin_tel input77"
              :show-all-levels="false"
              :props="this.agencylogic.roleProps"
              @change="areaNameChange"
              :placeholder="areaName"
            >
            </el-cascader>
          </el-col>
          <el-col :span="1.5">
            <div class="chaxun-button" @click="search"></div>
          </el-col>
          <el-col :span="1.5">
            <div class="chongzhi-button" @click="reset"></div>
          </el-col>
          <el-col :span="1.5" v-if="resetdeviceshow">
            <div class="reset-button" @click="resetdevice">批量重置设备配置</div>
          </el-col >
          <el-col :span="1.5" v-if="resetusershow">
            <div class="reset-button" @click="resetuser">批量重置设备用户</div>
          </el-col >
          <el-col :span="1.5" v-if="deletedeviceshow">
            <div class="reset-button" @click="deletedevic"  style="background: rgb(241, 55, 55) !important;">批量删除设备信息</div>
          </el-col >
          <el-col :span="1.5" v-if="bindingagencyshow">
            <div class="reset-button" @click="bindingagency">批量绑定机构</div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        imei:'',
        resetdeviceshow:false,
        bindingagencyshow:false,
        resetusershow:false,
        deletedeviceshow:false,
        chargeStatus:'',
        watchStatus:'',
        id:"",
        options:'',
        areaName: "请选择机构",
      };
    },
    props: {
      numTotal: {
        type: Number,
        default: 0,
      },
      search: {
        type: Function,
        default: function () {},
      },
      reset: {
        type: Function,
        default: function () {},
      },
      resetuser: {
        type: Function,
        default: function () {},
      },
      deletedevic: {
        type: Function,
        default: function () {},
      },
      resetdevice:{
        type: Function,
        default: function () {},
      },
      bindingagency:{
        type: Function,
        default: function () {},
      }
    },
    mounted() {
      this.load();
      var aa = JSON.parse(window.sessionStorage.getItem("agencyList")) 
      this.projectList = aa;
      let nodes = this.projectList.map((item) => ({
        value: item.id,
        label: item.agencyName,
        leaf: item.hasChildren === false,
      }));
      this.options = nodes;
    },
    methods: {
      areaNameChange (e) {
        this.id = e[e.length - 1];
        this.devicelogic.searchObj.agencyId = this.id
      },
      load() {
        this.devicelogic.reset();
        let href = window.location.href;
        if (href.indexOf("resetDevice") > 0) {
          this.resetdeviceshow = true;
        }
        if (href.indexOf("bindingAgency") > 0) {
          this.bindingagencyshow = true;
        }
        if (href.indexOf("resetUser") > 0) {
          this.resetusershow = true;
        }
        if (href.indexOf("deleteDevice") > 0) {
          this.deletedeviceshow = true;
        }
      },
      setimei() {
        this.devicelogic.searchObj.imei = this.imei;
        let interval = setInterval(() => {
          if (this.devicelogic.searchObj.imei === "") {
            this.imei = "";
            clearInterval(interval);
          }
        }, 1000);
      },
      setstatus() {
        this.devicelogic.searchObj.chargeStatus = this.chargeStatus;
        let interval = setInterval(() => {
          if (this.devicelogic.searchObj.chargeStatus === "") {
            this.chargeStatus = "";
            clearInterval(interval);
          }
        }, 1000);
      },
      watchstatus(){
        this.devicelogic.searchObj.watchStatus = this.watchStatus;
        let interval = setInterval(() => {
          if (this.devicelogic.searchObj.watchStatus === "") {
            this.watchStatus = "";
            clearInterval(interval);
          }
        }, 1000);
      }
    },
  };
  </script>
  <style>
  .col-cascader > .el-cascader > .el-input > .el-input__wrapper{
    width: 2rem;
    background: none !important;
    border: 1px solid #00BAFF !important;
    box-shadow: none !important;
    color: #000;
    font-size: 0.18rem;
    height: 100%;
  }
  .col-cascader > .el-cascader{
    height: 92%;
  }
  .col-cascader > .el-cascader > .el-input{
    height: 100%;
  }
  .el-cascader-menu__empty-text{
    display: none !important;
  }
</style>
  
  
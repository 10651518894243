<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <edit></edit>
            </div>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import top from "@/components/top";
  import edit from "@/components/agencyComponents/edit";
  export default { 
  components: {
    basecom,
    edit,
    top
  },
  };
  </script>
  
  
  
  
  
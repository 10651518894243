<template>
    <div class="body">
        <measure></measure>
    </div>
</template>
  
<script>
import measure from "@/components/patientComponents/measure.vue";

export default {
name: "addUser",
components: {
    measure,
},
};
</script>
  
<template>
    <div class="medical-list">
        <el-form ref="AddUser" :model="UserFrom" :rules="this.userlogic.rules" class="medical-from">
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;用户名称：</span>
                <el-form-item prop="userName">
                  <div>
                    <el-input
                        v-model="UserFrom.userName"
                        placeholder="请输入用户名称"
                        maxlength="30"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;手&nbsp;机&nbsp;号：</span>
                <el-form-item prop="mobile">
                  <div>
                    <el-input
                        v-model="UserFrom.mobile"
                        placeholder="请输入手机号"
                        maxlength="11"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;身份证号：</span>
                <el-form-item prop="idCard">
                  <div>
                    <el-input
                      class="admin_tel"
                      placeholder="请输入身份证号"
                      v-model="UserFrom.idCard"
                      maxlength="18"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;<span style="font-size: 0.3rem;"><i>*</i>&nbsp;电子邮箱：</span>
                <el-form-item prop="email">
                  <div>
                    <el-input
                        placeholder="请输入电子邮箱"
                        v-model="UserFrom.email"
                        maxlength="30"
                        type="email"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
               <span style="font-size: 0.18rem;position: relative;left: 0.45rem;"><i>*</i>&nbsp;短信接收状态：</span>
                <el-form-item prop="mobileReceiveStatus">
                  <div>
                    <el-select
                        v-model="UserFrom.mobileReceiveStatus"
                        placeholder="请选择"
                        class="admin_tel input77"
                        style="margin-left: 11%;"
                      >
                        <el-option
                          v-for="item in this.userlogic.TF"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
               <span style="font-size: 0.18rem;position: relative;left: 0.45rem;"><i>*</i>&nbsp;邮箱接收状态：</span>
                <el-form-item prop="emailReceiveStatus">
                  <div>
                    <el-select
                      v-model="UserFrom.emailReceiveStatus"
                      placeholder="请选择"
                      class="admin_tel input77"
                      style="margin-left: 11%;"
                    >
                      <el-option
                        v-for="item in this.userlogic.TF"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureAddUser('AddUser')" type="info"
            ></el-button
          >
          <el-button class="qd" @click="sureAddUser('AddUser')" type="primary"
            ></el-button
          >
        </div>
      </div>
   </template>
   <script>
   export default {
     name: "add",
     data() {
       return {
         UserFrom: {
           emailReceiveStatus: false,
           mobileReceiveStatus: false,
         },
         userList: [],
       };
     },
     mounted() {  
     },
     methods: {
      
       sureAddUser(ref) {
         this.$refs[ref].validate((valid) => {
           if (valid) {
             this.axios
               .post(this.baseURL + "User/UserAdd", this.UserFrom, this.headers())
               .then((res) => {
                 this.resolveData(res.data, "add");
               });
           } else {
             return false;
           }
         });
       },
       nosureAddUser() {
         this.$confirm("确认要关闭吗？")
           .then((_) => {
             done();
           })
           .catch((_) => {});
       },
     },
   };
   </script>
   
   
   
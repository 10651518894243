import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        userName:''
    },
    measureSearchObj:{
        page: 1,
        patientId:'',
    },
    page: 1,
    TF: [
        { value: 1, label: "男" },
        { value: 2, label: "女" }
    ],
    rules: {
        userName: [
            { required: true, message: "请输入人员名称", trigger: "blur" },
        ],
        height: [
            { required: true, message: "请输入身高", trigger: "blur" },
        ],
        weight: [
            { required: true, message: "请输入体重", trigger: "blur" },
        ],
        gender: [
            { required: true, message: "请选择性别", trigger: "change" },
        ],
        ssid: [
            { required: true, message: "请输入社保卡号", trigger: "blur" },
        ],
        idCard: [
            { required: true, message: '请填写证件号码', trigger: 'blur' },
            {
                pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                message: '证件号码格式有误！',
                trigger: 'change'
            }
        ],
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "userName", label: "人员名称" }, 
            { prop: "genderTypeName", label: "性别",width:90},
            { prop: "age", label: "年龄",width:90},
            { prop: "heights", label: "身高",width:160},
            { prop: "weights", label: "体重",width:160},
            { prop: "Cardid", label: "身份证号"},
            { prop: "ssid", label: "社保卡号"},
            { prop: "birthday", label: "出生日期"},
            { prop: "createDate", label: "创建日期"},
        ];
    }, 
    MeasurelistHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "userName", label: "人员姓名",width:120}, 
            { prop: "testTypeName", label: "测量内容",width:150},
            { prop: "testSettingTypeName", label: "测量类型",width:150},
            { prop: "testRecordFormat", label: "测量数据"},
            { prop: "createDate", label: "测量时间"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:120 });
        return header;
    },
    getPatientList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Patient/PatientList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    getMeasureList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Patient/TestRecordList", this.measureSearchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = { page:1,userName:''};
    },
    resetMeasure: function () {
        this.measureSearchObj = { page:1,patientId:''};
    }
}
<template>
    <div class="medical-list">
      <funListTable
        class="customer-table"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
        background:'#0A1634',color:'#fff'}"
        :tableData="this.funlogic.loadFunList()"
        :tableHeader="this.funlogic.opHeader()"
        :lazyLoad="this.funlogic.getChildFunList"
      >
          <template v-slot:btn="slotProps">
          <el-button
            type="primary"
            @click.stop="addFunc(slotProps.row.id, slotProps.row.functionName)"
          >
              添加子功能
          </el-button>
          </template>
      </funListTable>
    </div>
      <el-dialog
      :model-value="editroleDial"
        center
        class="medical-dialog"
        :before-close="nosureAddFun"
      >
      <div class="dialog-titile">添加子功能</div>
          <el-form 
            ref="addFunc" 
            :model="funFrom" 
            :rules="this.funlogic.rules"  
            style="overflow: auto;height: 5rem;"
            >
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;名&nbsp;称：</span>
              <el-form-item prop="fName">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fName"
                maxlength="30"
                placeholder="请输入功能名称"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>上&nbsp;级&nbsp;功&nbsp;能：</span>
              <el-form-item prop="fpName">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fpName"
                maxlength="30"
                :disabled="true"
                ></el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;区&nbsp;域：</span>
              <el-form-item prop="functionAreaType">
                <el-select
                v-model="funFrom.functionAreaType"
                placeholder="请选择"
                class="admin_tel input77"
                >
                  <el-option
                  v-for="item in this.funlogic.TF2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功能控制器：</span>
              <el-form-item prop="fController">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fController"
                placeholder="请输入功能控制器"
                ></el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>激&nbsp;活&nbsp;状&nbsp;态：</span>
              <el-form-item prop="status">
                <el-select
                v-model="funFrom.status"
                placeholder="请选择"
                class="admin_tel input77"
                >
                  <el-option
                  v-for="item in this.funlogic.TF1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;方&nbsp;法：</span>
              <el-form-item prop="fAction">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fAction"
                placeholder="请输入功能方法"
                ></el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;类&nbsp;型：</span>
              <el-form-item prop="fType">
                <el-select
                  v-model="funFrom.fType"
                  placeholder="请选择"
                  class="admin_tel input77"
                >
                  <el-option
                    v-for="item in this.funlogic.TFBtn"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;图&nbsp;标：</span>
              <el-form-item prop="fIcon">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fIcon"
                maxlength="20"
                placeholder="功能图标可为空"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;排&nbsp;序：</span>
              <el-form-item prop="fOrder">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fOrder"
                maxlength="30"
                type="Number"
                placeholder="请输入功能排序值"
                ></el-input>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>系&nbsp;统&nbsp;功&nbsp;能：</span>
              <el-form-item prop="isMainFunc">
                <el-select
                v-model="funFrom.isMainFunc"
                placeholder="请选择"
                class="admin_tel input77"
                >
                  <el-option
                    v-for="item in this.funlogic.TF"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>显&nbsp;示&nbsp;状&nbsp;态：</span>
              <el-form-item prop="isShow">
                <el-select
                v-model="funFrom.isShow"
                placeholder="请选择"
                class="admin_tel input77"
                >
                  <el-option
                    v-for="item in this.funlogic.Show"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="from-input from-input-dialog">
              &nbsp;<span><i>*</i>功&nbsp;能&nbsp;描&nbsp;述：</span>
              <el-form-item prop="fDescription">
                <el-input
                class="admin_tel input77"
                v-model="funFrom.fDescription"
                maxlength="100"
                placeholder="请输入功能描述"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureAddFun('addFunc')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureAddFun('addFunc')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
   
  </template>
  <script>
  import funListTable from "@/components/listComponents/treeList";
  
  export default {
    name: "addFunc",
    components: { funListTable },
    data() {
      return {
        editroleDial: false,
        funFrom: {},
        mbFpid: "",
      };
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
      },
      nosureAddFun() {
        //弹窗关闭
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editroleDial = false;
            done();
          })
          .catch((_) => {});
      },
      sureAddFun(ref) {
        //下面为验证,需要设置form的ref
        this.$refs[ref].validate((valid) => {
          if (valid) {
            //弹窗关闭
            this.editroleDial = false;
            let funFrom = this.funFrom;
            //这里写请求
            funFrom.fpid = this.mbFpid;
            funFrom.fOrder = Number(funFrom.fOrder);
            delete funFrom.fpName;
            this.axios
              .post(
                this.baseURL + "Function/FunctionAdd",
                funFrom,
                this.headers()
              )
              .then((res) => {
                this.resolveData(res.data, "add");
              });
          } else {
            return false;
          }
        });
      },
      addFunc(id, name) {
        this.editroleDial = true;
        this.funFrom = {};
        this.funFrom.fpName = name;
        //data中加入当前所选功能的fpid
        this.mbFpid = id;
        //数据清空
        //验证提示消除
        this.resetForm("addFunc");
      },
      //清除验证提示方法
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
    },
  };
  </script>
  
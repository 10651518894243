import { createRouter, createWebHashHistory } from 'vue-router'
import Login from "@/views/Login"
import Index from '@/views/Index'
import Statistics from '@/views/Statistics'
import mapDialogVisible from '@/views/mapDialogVisible'
// import Record from '@/views/Record'

import UserIndex from "@/views/userControl/userIndex"
import AddUser from "@/views/userControl/addUser"
import EditUser from '@/views/userControl/editUser'
import AssignRole from '@/views/userControl/assignRole'

import RoleIndex from '@/views/roleControl/roleIndex'
import AddRole from '@/views/roleControl/addRole'
import EditRole from "@/views/roleControl/editRole"
import AssignFun from "@/views/roleControl/assignFun"
import AssignAgency from "@/views/roleControl/assignAgency"

import FuncIndex from '@/views/funcControl/funcIndex'
import AddFunc from '@/views/funcControl/addFunc'
import EditFunc from '@/views/funcControl/editFunc'

import PatientIndex from '@/views/patientControl/patientIndex'
import AddPatient from '@/views/patientControl/addPatient'
import EditPatient from '@/views/patientControl/editPatient'
import MeasurePatient from '@/views/patientControl/measurePatient'

import DeviceIndex from '@/views/deviceControl/deviceIndex'
import BindingDevice from '@/views/deviceControl/bindingDevice'
import ResetDevice from '@/views/deviceControl/resetDevice'
import SwitchDevice from '@/views/deviceControl/switchDevice'
import BindingPhone from '@/views/deviceControl/bindingphone'
import UploadDeviceExcel from '@/views/deviceControl/uploadDeviceExcel'
import ExportDeviceExcel from '@/views/deviceControl/exportDeviceExcel'
import BindingAgency from '@/views/deviceControl/bindingAgency'
import ResetUser from '@/views/deviceControl/resetUser'
import DeleteDevice from '@/views/deviceControl/deleteDevice'

import AgencyIndex from '@/views/agencyControl/agencyIndex'
import AddAgency from '@/views/agencyControl/addAgency'
import EditAgency from '@/views/agencyControl/editAgency'

import AbnormalIndex from '@/views/abnormalControl/abnormalIndex'
import ConfigIndex from '@/views/ConfigControl/ConfigIndex'

let otherRouter = [
  { path: "/", redirect: '/Login.html' },
  { path: "/Login.html", name: "登录", component: Login },
  { path: "/Index.html", name: "首页", component: Index },
  { path: "/Statistics.html", name: "数据统计", component: Statistics },
  { path: "/abnormalControl/abnormalIndex.html", name: "异常统计", component: AbnormalIndex },
  { path: "/ConfigControl/ConfigIndex.html", name: "修改配置", component: ConfigIndex },
  { path: "/mapDialogVisible.html", name: "精准定位", component: mapDialogVisible },
//   { path: "/Record.html", name: "后台业务操作记录", component: Record },
]

let userRouter = [
  { path: '/userControl/userIndex.html', name: '用户列表', component: UserIndex },
  { path: '/userControl/addUser.html', name: '添加用户', component: AddUser },
  { path: '/userControl/editUser.html', name: '编辑用户', component: EditUser },
  { path: '/userControl/assignRole.html', name: '分配角色', component: AssignRole },
];

let roleRouter = [
  { path: "/roleControl/roleIndex.html", name: "角色列表", component: RoleIndex },
  { path: "/roleControl/addRole.html", name: "添加角色", component: AddRole },
  { path: "/roleControl/editRole.html", name: "修改角色", component: EditRole },
  { path: "/roleControl/assignFun.html", name: "分配功能", component: AssignFun },
  { path: "/roleControl/assignAgency.html", name: "分配机构", component: AssignAgency },
];

let funRouter = [
  { path: "/funcControl/funcIndex.html", name: "功能列表", component: FuncIndex },
  { path: "/funcControl/addFunc.html", name: "添加功能", component: AddFunc },
  { path: "/funcControl/editFunc.html", name: "修改功能", component: EditFunc, }
];

let PatientRouter = [
  { path: "/patientControl/patientIndex.html", name: "人员列表", component: PatientIndex },
  { path: "/patientControl/addPatient.html", name: "添加人员", component: AddPatient },
  { path: "/patientControl/editPatient.html", name: "修改人员", component: EditPatient},
  { path: "/patientControl/measurePatient.html", name: "测量记录", component: MeasurePatient}
];

let DeviceRouter = [
  { path: "/deviceControl/deviceIndex.html", name: "设备列表", component: DeviceIndex },
  { path: "/deviceControl/bindingDevice.html", name: "绑定人员", component: BindingDevice },
  { path: "/deviceControl/resetDevice.html", name: "批量重置手表", component: ResetDevice, },
  { path: "/deviceControl/switchDevice.html", name: "设备开关", component: SwitchDevice, },
  { path: "/deviceControl/bindingphone.html", name: "绑定手机号", component: BindingPhone, },
  { path: "/deviceControl/uploadDeviceExcel.html", name: "导入设备", component: UploadDeviceExcel, },
  { path: "/deviceControl/exportDeviceExcel.html", name: "导出设备", component: ExportDeviceExcel, },
  { path: "/deviceControl/bindingAgency.html", name: "绑定机构", component: BindingAgency, },
  { path: "/deviceControl/resetUser.html", name: "批量重置手表用户", component: ResetUser, },
  { path: "/deviceControl/deleteDevice.html", name: "批量删除手表设备信息", component: DeleteDevice, },
];

let AgencyRouter = [
  { path: "/agencyControl/agencyIndex.html", name: "机构列表", component: AgencyIndex },
  { path: "/agencyControl/addAgency.html", name: "添加机构", component: AddAgency },
  { path: "/agencyControl/editAgency.html", name: "修改机构", component: EditAgency },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: []
    .concat(otherRouter)
    .concat(userRouter)
    .concat(roleRouter)
    .concat(funRouter)
    .concat(PatientRouter)
    .concat(DeviceRouter)
    .concat(AgencyRouter)
});
router.beforeEach((to, from, next) => {
  next()
});
router.afterEach((transition) => {
  let name = transition.name
  let item = router.options.routes.filter((ele) => { return ele.name == name })
  setTitle(item[0].name)
});
function setTitle(title) {
  document.title = title
  var mobile = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(mobile)) {
    var iframe = document.createElement("iframe")
    iframe.style.display = "none"
    var iframeCallback = function () {
      setTimeout(function () {
        iframe.removeEventListener("load", iframeCallback)
        document.body.removeChild(iframe)
      }, 0)
    }
    iframe.addEventListener("load", iframeCallback)
    document.body.appendChild(iframe)
  }
}
export default router

<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <roleList
                    class="customer-table"
                    :row-class-name="tableRowClassName"
                    :header-cell-style="{ 
                    background:'#0A1634',color:'#fff'}"
                    :tableData="this.rolelogic.roleList()"
                    :tableHeader="this.rolelogic.listHeader()"
                    :lazyLoad="this.rolelogic.getChildRoleList"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import roleList from "@/components/listComponents/treeList";
export default {
    components: {
    basecom,
    roleList,
    top
},
data() {
    return {
    };
},
mounted() {
},
methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
    },
},
};
</script>
<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list" style="padding-top: 0.1rem;">
                    <div id="columnar-box" @click="abnormalgo()"></div>
                    <el-dialog
                        class="medical-dialogs edit-dialog"
                        :model-value="abnormalshow"
                        center
                        :before-close="nosureAbnormal"
                    >
                        <div class="abnormalgo-text">{{ abnormaltext }}异常统计</div>
                        <abnormallist
                        :header-cell-style="tableHeaderColor"
                        :cell-style="columnbackgroundStyle"
                        style="height: 85%; !important"
                        class="customer-table"
                        :tableHeader="this.abnormallogic.listHeader()"
                        :tableData="abnormallist"
                        :page="this.abnormallogic.searchObj.page"
                        :row-class-name="tableRowClassName"
                        ></abnormallist>
                        <pager
                        :page="this.abnormallogic.searchObj.page"
                        :numTotal="numTotal"
                        :handleCurrentChange="abnormalgo"
                        >
                        </pager>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import abnormallist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
export default {
    components: {
        basecom,
        top,
        abnormallist,
        pager
    },
    data() {
        return {
            abnormalshow:false,
            abnormallist:[],
            abnormaltext:'',
            numTotal:'',
            page:1
        };
    },
    mounted() {
        this.charts.columnar()
    },
    methods: {
        tableHeaderColor ({columnIndex}) {
            if(this.abnormallist != ''){
                if (columnIndex == this.charts.maintestType + 1) {
                    return { 'background':'#841723','color':'#fff'};
                }else{
                    return { 'background':'#102049','color':'#fff'};
                }
            }else{
                return { 'background':'#102049','color':'#fff'};
            }
        },
        columnbackgroundStyle({ columnIndex }) {
            if (columnIndex == this.charts.maintestType + 1) {
                return { 'background':'#841723'};
            }
        },
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        abnormalgo(page = 1){
            this.abnormallogic.searchObj.page = page;
            if(this.charts.maintestType == ''){
            }else{
                this.abnormallogic.searchObj.testType = this.charts.maintestType
                this.abnormaltext = this.charts.maintesttext
                this.abnormallogic.gethomeList().then((res) => {
                    this.abnormalshow = true
                    res.testRecordList.forEach(item=>{
                        console.log(item)
                        if(item.isFall == true){
                            item.isFall = "是";
                        }else{
                            item.isFall = "否";
                        }
                    })
                    this.abnormallist = res.testRecordList
                    this.numTotal = res.total;
                });
            }
        },
        nosureAbnormal(ref) {
            this.$confirm("确认要关闭吗？")
                .then((_) => {
                    this.abnormalshow = false;
                    this.charts.maintesttext = ''
                    this.charts.maintestType = ''
                done();
                })
                .catch((_) => {});
        },
    },
};
</script>

<style>
#columnar-box{
    width: 100%;
    height: 100%;
}
#columnar-box > div:nth-child(2){
    z-index: 9 !important;
}

</style>
<template>
    <div class="people-top"></div>
    <div class="people-bottom">
        <div id="people-echarts"></div>
    </div>
</template>
<script>
export default{
    data(){
        return{

        }
    },
    mounted(){
        this.charts.people()
    },
    methods:{
        
    }
}
</script>
<style>

</style>
<template>
    <div class="medical-list">
        <roleList
        class="customer-table"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableData="this.rolelogic.roleList()"
        :tableHeader="this.rolelogic.opHeader()"
        :lazyLoad="this.rolelogic.getChildRoleList"
      >
        <template v-slot:btn="slotProps">
          <el-button type="primary" @click.stop="assignFunc(slotProps.row)">
            分配功能
          </el-button>
        </template>
      </roleList>
    </div>
      <el-dialog
        class="medical-dialogs"
        :model-value="assignform"
        center
        :before-close="noChange"
      >
      <div class="dialog-titile">请选择需要分配的功能</div>
        <el-tree
          ref="functree"
          :data="fflist"
          show-checkbox
          node-key="id"
          :props="props"
        >
        </el-tree>
        <span slot="footer" class="sureBtn" style="margin-top:-0.2rem;">
          <el-button class="qx" @click="noChange()" type="info"></el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="okChange()" type="primary"
            ></el-button
          >
        </span>
      </el-dialog>
  </template>
  <script>
  import roleList from "@/components/listComponents/treeList";
  import top from "@/components/top";
  export default {
    name: "assignfun",
    components: { roleList,top },
    data() {
      return {
        assignform: false,
        roleList: [],
        fflist: [],
        multipleSelection: null,
        props: {
          children: "children",
          label: "functionName",
        },
        roleid: "",
      };
    },
    mounted() {
      this.load();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
      load() {
        let aaa = {
          fpid: this.emptyId,
        };
        let roles = this.getRole();
        let toprole = false;
        roles.forEach((role) => {
          this.roleList.push(role);
          if (role.rpid == this.emptyId)
            toprole = true
        });
        if (toprole) {
        this.axios
          .post(
            this.baseURL + "Function/FunctionListWithChild",
            aaa,
            this.headers()
          )
          .then((res) => {
            var data = this.resolveData(res.data);
            this.fflist = data.functionList;
          });
      }
      else {
        this.roleList.forEach((role) => {
          let a = {
            id: role.id,
          };
          this.axios
            .post(this.baseURL + "Role/GetRoleFuncList", a, this.headers())
            .then((res) => {
              var data = this.resolveData(res.data);

              data.functionList.forEach((fun) => {
                let f = this.fflist.find(element => element.id == fun.id);
                if (f == null)
                  this.fflist.push(fun);

              });
              this.fflist = this.functiontoTree(this.fflist)
            });
        })
      }
      },
      assignFunc(row) {
        this.assignform = true;
  
        this.rolefuncList = [];
  
        let a = {
          id: row.id,
        };
        this.roleid = row.id;
  
        this.axios
          .post(this.baseURL + "Role/GetRoleFuncList", a, this.headers())
          .then((res) => {
            this.$refs.functree.setCheckedKeys([]);
  
            let data = this.resolveData(res.data);
  
            data.functionList.forEach((element) => {
              this.$refs.functree.setChecked(element.id, true, false);
            });
          });
      },
  
      noChange() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.assignform = false;
            done();
          })
          .catch((_) => {});
      },
      okChange() {
        this.assignform = false;
  
        let nodes = this.$refs.functree.getCheckedKeys();
        let a = {
          functionIdList: nodes,
          roleId: this.roleid,
        };
        this.axios
          .post(this.baseURL + "Role/AssignFunction", a, this.headers())
          .then((res) => {
            this.resolveData(res.data, "assign");
          });
      },
    },
  };
  </script>
<template>
  <router-view />
</template>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  overflow: hidden;
}
.body{
  height: 100%;
}
</style>

<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search" style="height: 6%;">
                    <deviceSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></deviceSearch>
                    </div>
                   <div class="switch-data-box">
                    <div class="wudata" v-if="datashow">
                        暂无数据
                    </div>
                    <div class="switch-box" v-for="item in watchList">
                        <div class="switch-box-top">
                            <div class="switch-box-top-name">{{ item.userName }}</div>
                            <div class="switch-box-top-data">
                                <span>•</span>{{ item.genderTypeName }}<span>•</span>{{ item.age }}岁<span>•</span><span>IMEI序列号:</span><span style="color: #fff;">{{ item.imei }}</span>
                            </div>
                        </div>
                        <div class="switch-box-center">
                            <div class="switch-button" v-for="biu in item.watchOnOffList">
                                <div style="width: 50%;">{{ biu.name }}</div>
                                <el-switch
                                @change="switchchange(biu,item.id)"
                                v-model="biu.onOff"
                                inactive-color="#585858">
                                </el-switch> 
                            </div>
                        </div>
                    </div>
                   </div>
                   <div style="text-align: center;">
                        <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :current-page="this.devicelogic.searchObj.page"
                            :page-size="pagesize"
                            layout="total,  prev, pager, next, jumper"
                            :total="numTotal"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
export default {
    components: {
        basecom,
        deviceSearch,
        top
    },
    data() {
        return {
            watchList: [],
            page: 1,
            numTotal: 0,
            value1:'',
            pagesize:3,
            datashow:false
        };
    },
    created(){
        this.PageSize = 3
    },
    mounted() {
        this.handleCurrentChange();
    },
    methods: {
        switchchange(row,id){
            this.devicelogic.searchOff.id = id;
            this.devicelogic.searchOff.onOffOpCode = row.onOffOpCode
            this.devicelogic.searchOff.onOff = row.onOff
            this.devicelogic.getWatchOnOff().then((res) => {
                if(res == null){
                    row.onOff = !row.onOff
                }
            });
        },
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.devicelogic.searchObj.page = page;
            this.devicelogic.getWatchOnOffList().then((res) => {
                if(res.watchDetailsList == ''){
                    this.datashow =true
                    this.watchList = []
                    this.numTotal = 0
                }else{
                    this.datashow =false
                    this.watchList = res.watchDetailsList;
                    this.numTotal = res.total;
                }
            });
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
    },
    };
</script>
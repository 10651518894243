import proxy from '../main'
export default {
    maintestType: '',
    maintesttext:'',
    // 异常统计图表
    columnar: function () {
        var myChart = proxy.echarts.init(document.getElementById('columnar-box'));
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose(); //销毁
         }
        myChart = proxy.echarts.init(document.getElementById('columnar-box'));
        // 绘制左侧面
        const CubeLeft = proxy.echarts.graphic.extendShape({
            shape: {
                x: 0,
                y: 0
            },
            buildPath: function(ctx, shape) {
                const xAxisPoint = shape.xAxisPoint
                const c0 = [shape.x, shape.y]
                const c1 = [shape.x - 50, shape.y - 20]
                const c2 = [xAxisPoint[0] - 50, xAxisPoint[1] - 13]
                const c3 = [xAxisPoint[0], xAxisPoint[1]]
                ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
            }
        })
        // 绘制右侧面
        const CubeRight = proxy.echarts.graphic.extendShape({
            shape: {
                x: 0,
                y: 0
            },
            buildPath: function(ctx, shape) {
                const xAxisPoint = shape.xAxisPoint
                const c1 = [shape.x, shape.y]
                const c2 = [xAxisPoint[0], xAxisPoint[1]]
                const c3 = [xAxisPoint[0] + 50, xAxisPoint[1] - 9]
                const c4 = [shape.x + 50, shape.y - 9]
                ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
            }
        })
        // 绘制顶面
        const CubeTop = proxy.echarts.graphic.extendShape({
            shape: {
                x: 0,
                y: 0,
            },
            buildPath: function(ctx, shape) {
                const c1 = [shape.x, shape.y]
                const c2 = [shape.x + 50, shape.y - 9]
                const c3 = [shape.x  + 10, shape.y - 30]
                const c4 = [shape.x - 50, shape.y - 20]
                ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
            }
        })
        // 注册三个面图形
        proxy.echarts.graphic.registerShape('CubeLeft', CubeLeft)
        proxy.echarts.graphic.registerShape('CubeRight', CubeRight)
        proxy.echarts.graphic.registerShape('CubeTop', CubeTop)

        const VALUE = []
        const TEXT =[]
        const TESTYPE = []
        var aa = {}
        var option
        proxy.axios.post(proxy.baseURL + 'Patient/ExceptionStatistical',aa, proxy.headers()).then(res=>{
            res.data.data.patientStatisticalList.forEach((item) => {
                VALUE.push(item.amount)
                TEXT.push(item.testTypeName)
                TESTYPE.push(item.testType)
            })
            option = {
                backgroundColor: "#000",
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: function(params, ticket, callback) {
                        const item = params[0]
                        return item.value + '人';
                    }
                },
                grid: {
                    left: 40,
                    right: 40,
                    bottom: 100,
                    top: 100,
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    testType:TESTYPE,
                    data: TEXT,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'white'
                        }
                    },
                    offset: 25,
                    axisTick: {
                        show: false,
                        length: 9,
                        alignWithLabel: true,
                        lineStyle: {
                            color: '#7DFFFD'
                        }
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 16
                    },
                },
                yAxis: {
                    min:0,
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'white'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type:"dashed",
                            color:"rgba(255,255,255,0.1)"
                        },
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 16,
                        
                    },
                    boundaryGap:["0.5","0"],
                },
                series: [
                   {
                    type: 'custom',
                    renderItem: (params, api) => {
                        const location = api.coord([api.value(0), api.value(1)])
                        var color = proxy.echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(37,170,254,1)'
                            },
                            {
                                offset: 0.8,
                                color: 'rgba(37,170,254,0.1)'
                            }
                        ])
                        return {
                            type: 'group',
                            children: [{
                                type: 'CubeLeft',
                                shape: {
                                    api,
                                    xValue: api.value(0),
                                    yValue: api.value(1),
                                    x: location[0],
                                    y: location[1],
                                    xAxisPoint: api.coord([api.value(0), 0])
                                },
                                style: {
                                    fill: color
                                }
                            }, {
                                type: 'CubeRight',
                                shape: {
                                    api,
                                    xValue: api.value(0),
                                    yValue: api.value(1),
                                    x: location[0],
                                    y: location[1],
                                    xAxisPoint: api.coord([api.value(0), 0])
                                },
                                style: {
                                    fill: color
                                }
                            }, {
                                type: 'CubeTop',
                                shape: {
                                    api,
                                    xValue: api.value(0),
                                    yValue: api.value(1),
                                    x: location[0],
                                    y: location[1],
                                    xAxisPoint: api.coord([api.value(0), 0])
                                },
                                style: {
                                    fill: color
                                }
                            }]
                        }
                    },
                    data: VALUE,
                },{
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            fontSize: 16,
                            color: '#fff',
                            offset: [2, -25]
                        }
                    },
                    tooltip: {
                        
                    },
                }]
            }
             // 点击事件
            myChart.setOption(option);
            myChart.off('click'); //防止触发两次点击事件
            myChart.getZr().on('click', (params) => {
                let pointInPixel = [params.offsetX, params.offsetY];
                if (myChart.containPixel('grid', pointInPixel)) {
                    let pointInGrid = myChart.convertFromPixel({
                        seriesIndex: 0
                    }, pointInPixel);
                    let xIndex = pointInGrid[0]; //索引
                    let handleIndex = Number(xIndex);
                    let seriesObj = myChart.getOption(); //图表object对象
                    var op = myChart.getOption();
                    //获得图表中点击的列
                    var month = op.xAxis[0].data[handleIndex];  //获取点击的列名
                    var months = op.xAxis[0].testType[handleIndex];  //获取点击的列名
                    // console.log(month,months);
                    this.maintesttext = month
                    this.maintestType = months;
                };
            });
            
            var app = {
                currentIndex: -1,
            };
            var aa =  setInterval(function () {
                var dataLen = option.series[0].data.length;
                // 取消之前高亮的图形
                myChart.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
                app.currentIndex = (app.currentIndex + 1) % dataLen;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
                // 显示 tooltip
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
            }, 3000);
            myChart.setOption(option);
            setTimeout(function (){
                window.addEventListener("resize",function(){
                    myChart.resize();
            })
            },200)
            option && myChart.setOption(option);
            //if(option){
            //    myChart.setOption(option);
            //}
        })
    },

    // 激活数量图表
    activate: function(){
        var myChart = proxy.echarts.init(document.getElementById('activate-echarts'));
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose(); //销毁
        }
       
        myChart = proxy.echarts.init(document.getElementById('activate-echarts'));
        
        let bgColor = "#fff";
        let color = [
            "#0090FF",
            "#36CE9E",
            "#FFC005",
            "#FF515A",
            "#8B5CFF",
            "#00CA69"
        ];
        let echartData = [{
                name: "2023-01",
                value1: 21
            },
            {
                name: "2023-02",
                value1: 27
            },
            {
                name: "2023-03",
                value1: 25
            },
            {
                name: "2023-04",
                value1: 21
            },
            {
                name: "2023-05",
                value1: 29
            },
            {
                name: "2023-06",
                value1: 18
            },
            {
                name: "2023-07",
                value1: 25
            },
            {
                name: "2023-08",
                value1: 27
            }
        ];
        proxy.chartslogic.WatchStatconfigList().then((res) => {
            echartData= res.watchBindStatList
            echartData.forEach(item=>{
                item.value1 = item.notBindCount 
                item.name = item.date
            })
            let xAxisData = echartData.map(v => v.name);
            let yAxisData2 = echartData.map(v => v.value1);
            const hexToRgba = (hex, opacity) => {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            }
            
            var option = {
                backgroundColor: 'rgba(0,0,0,0)',
                color: '#36CE9E',
                legend: {
                    right: 1,
                    top: 1,
                    show:false
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function(params) {
                        let html = '';
                        params.forEach(v => {
                            html += `<div style="color: #fff;font-size: 14px;line-height: 24px">
                            ${v.name}月激活
                            <span style="color:#3BC56B;font-weight:700;font-size: 18px">${v.value}</span>
                            台`;
                        })
                        return html
                    },
                    extraCssText: 'background: #10304C; border-radius: 10px;color: #fff;border:0;',
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            color: 'rgba(225,225,225,0.2)',
                            shadowColor: 'rgba(225,225,225,1)',
                            shadowBlur: 5
                        }
                    }
                },
                grid: {
                    top: 60,
                    bottom:0,
                    left:20,
                    right:30,
                    containLabel: true,
                },
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        formatter: '{value}',
                        textStyle: {
                            color: "#fff"
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#fff"
                        }
                    },
                     //网格样式
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type: "dashed",
                            color: ['#182A3A'],
                            width: 1,
                        }
                    },
                    data: xAxisData
                }],
                yAxis: [{
                    type: "value",
                    name: '单位:台',
                    axisLabel: {
                        textStyle: {
                            color: "#fff"
                        }
                    },
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: 12,
                        lineHeight: 40
                    },
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                            color: "#182A3A"
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                }],
                series: [
                        {
                    name: "激活台数",
                    type: "line",
                    smooth: true,
                    // showSymbol: false,
                    symbolSize: 8,
                    zlevel: 3,
                    lineStyle: {
                        normal: {
                            color: '#38BC68',
                            shadowBlur: 3,
                            shadowColor: hexToRgba('#36CE9E', 0.5),
                        }
                    },
                    areaStyle: {
                        normal: {
                            color: proxy.echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [{
                                        offset: 0,
                                        color: hexToRgba('#36CE9E', 0.3)
                                    },
                                    {
                                        offset: 1,
                                        color: hexToRgba('#36CE9E', 0.1)
                                    }
                                ],
                                false
                            ),
                            shadowColor: hexToRgba('#36CE9E', 0.1),
                            shadowBlur: 10
                        }
                    },
                    data: yAxisData2
                }]
            };
             
            var app = {
                currentIndex: -1,
            };
            var aa =  setInterval(function () {
                var dataLen = option.series[0].data.length;
                // 取消之前高亮的图形
                myChart.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
                app.currentIndex = (app.currentIndex + 1) % dataLen;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
                // 显示 tooltip
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0,
                    dataIndex: app.currentIndex,
                });
            }, 3000);
            myChart.setOption(option);
            setTimeout(function (){
                window.addEventListener("resize",function(){
                    myChart.resize();
            })
            },200)
            option && myChart.setOption(option);
        })
       
    },

    // 测量项人数
    people: function(){
        var myChart = proxy.echarts.init(document.getElementById('people-echarts'));
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose(); //销毁
        }
        myChart = proxy.echarts.init(document.getElementById('people-echarts'));
        var barWidth = 30;
        let Ydata1 = []
        let Ydata2 = []
        let xData = []
        var color2 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#FF7844',
                },
                {
                    offset: 0,
                    color: 'rgba(255,120,68,.16)',
                },
            ],
        };
        var color1 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#3BC56B',
                },
                {
                    offset: 0,
                    color: 'rgba(59,197,107,.16)',
                },
            ],
        };
        proxy.chartslogic.PatientStatconfigList().then((res) => {
            res.patientTestStatusStatList.forEach(item=>{
                Ydata1.push(item.normalCount)
                Ydata2.push(item.abNormalCount)
                xData.push(item.testTypeName)
            })
            var option = {
                // backgroundColor: '#fff', //背景色
                legend: {
                    selectedMode:false,
                    data: ['测量正常', '测量异常'],
                    itemWidth: 12,
                    itemHeight: 12,
                    icon: 'rect',
                    top: "5%",
                    right: "0",
                    textStyle: {
                        color: '#fff',
                        fontWeight: 'normal',
                        fontSize: 12,
                    },
                },
                //提示框
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                },
                grid: {
                    top: '20%',
                    left: '5%',
                    bottom: '1%',
                    right: '5%',
                    containLabel: true,
                },
                animation: false,
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                        axisLabel: {
                            inside: false,
                            textStyle: {
                            color: '#fff',
                            fontWeight: 'normal',
                            fontSize: 12,
                            },
                            margin: 20, //刻度标签与轴线之间的距离。
                        },
                        data: xData,
                    },
                    {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitArea: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        data: xData,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        show: true,
                        name: "单位：人",
                        type: 'value',
                        nameTextStyle: {
                            color: "#fff",
                            fontSize: 12,
                            padding: [0, 0, 10, -30], //name文字位置 对应 上右下左
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                            color: '#fff',
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                            color: "#606C76",
                            type: "dashed"
                            },
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '测量正常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#3BC56B',
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#FF7844',
                        zlevel: 2,
                        data: Ydata2,
                    },
    
                    {
                        name: '测量正常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            //
                            // color: '',
                            color: color1,
                            borderColor: color1,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            // color: 'rgba(119,167,255,1)',
                            color: '#3BC56B',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            // color: 'rgba(255,164,41,.16)',
                            color: color2,
                            borderColor: color2,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            color: '#FF7844',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata2,
                    },
                    {
                        name: '黄色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', 5],
                        z: 12,
                        color: '#3BC56B',
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                        ],
                    },
                    {
                        name: '蓝色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', 5],
                        z: 12,
                        color: '#FF7844',
                        show: false,
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            }
                        ],
                    }
                ],
            };
            myChart.setOption(option);
            setTimeout(function (){
                window.addEventListener("resize",function(){
                    myChart.resize();
            })
            },200)
            option && myChart.setOption(option);
        })
       
    },

    // 年龄段测量项（血压）
    agepeoplenumtop: function(){
        var myChart = proxy.echarts.init(document.getElementById('agepeoplenumtop-echarts'));
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose(); //销毁
        }
        myChart = proxy.echarts.init(document.getElementById('agepeoplenumtop-echarts'));
        var barWidth = 18;
        let Ydata1 = []
        let Ydata2 = []
        let xData = []
        var color2 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#FF7844',
                },
                {
                    offset: 0,
                    color: 'rgba(255,120,68,.16)',
                },
            ],
        };
        var color1 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#3BC56B',
                },
                {
                    offset: 0,
                    color: 'rgba(59,197,107,.16)',
                },
            ],
        };
        proxy.chartslogic.type.testType = 3
        proxy.chartslogic.PatientTypeStatconfigList().then((res) => {
            console.log(res)
            res.patientTestDataStatusAgeStatList.forEach(item=>{
                Ydata1.push(item.normalCount)
                Ydata2.push(item.abNormalCount)
                xData.push(item.ageRange + '岁')
            })
            var option = {
                // backgroundColor: '#fff', //背景色
                legend: {
                    selectedMode:false,
                    data: ['测量正常', '测量异常'],
                    itemWidth: 12,
                    itemHeight: 12,
                    icon: 'rect',
                    top: "5%",
                    right: "0",
                    textStyle: {
                        color: '#fff',
                        fontWeight: 'normal',
                        fontSize: 12,
                    },
                },
                //提示框
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                },
                grid: {
                    top: '20%',
                    left: '5%',
                    bottom: '1%',
                    right: '5%',
                    containLabel: true,
                },
                animation: false,
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                        axisLabel: {
                            inside: false,
                            textStyle: {
                            color: '#fff',
                            fontWeight: 'normal',
                            fontSize: 10,
                            },
                            margin: 20, //刻度标签与轴线之间的距离。
                        },
                        data: xData,
                    },
                    {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitArea: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        data: xData,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        show: true,
                        name: "单位：人",
                        type: 'value',
                        nameTextStyle: {
                            color: "#fff",
                            fontSize: 10,
                            padding: [0, 0,5, 230], //name文字位置 对应 上右下左
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                            color: '#fff',
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                            color: "#606C76",
                            type: "dashed"
                            },
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '测量正常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#3BC56B',
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#FF7844',
                        zlevel: 2,
                        data: Ydata2,
                    },
    
                    {
                        name: '测量正常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            //
                            // color: '',
                            color: color1,
                            borderColor: color1,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            // color: 'rgba(119,167,255,1)',
                            color: '#3BC56B',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            // color: 'rgba(255,164,41,.16)',
                            color: color2,
                            borderColor: color2,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            color: '#FF7844',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata2,
                    },
                    {
                        name: '黄色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', 5],
                        z: 12,
                        color: '#3BC56B',
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                        ],
                    },
                    {
                        name: '蓝色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', 5],
                        z: 12,
                        color: '#FF7844',
                        show: false,
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            }
                        ],
                    }
                ],
            };
            myChart.setOption(option);
            setTimeout(function (){
                window.addEventListener("resize",function(){
                    myChart.resize();
            })
            },200)
            option && myChart.setOption(option);
        })
       
    },

    // 年龄段测量项（心率）
    agepeoplenumbottom: function(){
        var myChart = proxy.echarts.init(document.getElementById('agepeoplenumbottom-echarts'));
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose(); //销毁
        }
        myChart = proxy.echarts.init(document.getElementById('agepeoplenumbottom-echarts'));
        var barWidth = 18;
        let Ydata1 = []
        let Ydata2 = []
        let xData = []
        var color2 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#FF7844',
                },
                {
                    offset: 0,
                    color: 'rgba(255,120,68,.16)',
                },
            ],
        };
        var color1 = {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: 'linear',
            global: false,
            colorStops: [
                {
                    //第一节下面
                    offset: 1,
                    color: '#3BC56B',
                },
                {
                    offset: 0,
                    color: 'rgba(59,197,107,.16)',
                },
            ],
        };
        proxy.chartslogic.type.testType = 1
        proxy.chartslogic.PatientTypeStatconfigList().then((res) => {
            console.log(res)
            res.patientTestDataStatusAgeStatList.forEach(item=>{
                Ydata1.push(item.normalCount)
                Ydata2.push(item.abNormalCount)
                xData.push(item.ageRange + '岁')
            })
            var option = {
                // backgroundColor: '#fff', //背景色
                legend: {
                    selectedMode:false,
                    data: ['测量正常', '测量异常'],
                    itemWidth: 12,
                    itemHeight: 12,
                    icon: 'rect',
                    top: "5%",
                    right: "0",
                    textStyle: {
                        color: '#fff',
                        fontWeight: 'normal',
                        fontSize: 12,
                    },
                },
                //提示框
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                },
                grid: {
                    top: '20%',
                    left: '5%',
                    bottom: '1%',
                    right: '5%',
                    containLabel: true,
                },
                animation: false,
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                        axisLabel: {
                            inside: false,
                            textStyle: {
                            color: '#fff',
                            fontWeight: 'normal',
                            fontSize: 10,
                            },
                            margin: 20, //刻度标签与轴线之间的距离。
                        },
                        data: xData,
                    },
                    {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitArea: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        data: xData,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        show: true,
                        name: "单位：人",
                        type: 'value',
                        nameTextStyle: {
                            color: "#fff",
                            fontSize: 10,
                            padding: [0, 0,5, 230], //name文字位置 对应 上右下左
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                            color: '#fff',
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                            color: "#606C76",
                            type: "dashed"
                            },
                        },
                        axisLine: {
                            show: true,
                            textStyle: {
                            color: "#019bdd",
                            },
                            lineStyle: {
                            color: "#606C76", //刻度线的颜色
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '测量正常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#3BC56B',
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', -5],
                        symbolPosition: 'end',
                        z: 15,
                        color: '#FF7844',
                        zlevel: 2,
                        data: Ydata2,
                    },
    
                    {
                        name: '测量正常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            //
                            // color: '',
                            color: color1,
                            borderColor: color1,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            // color: 'rgba(119,167,255,1)',
                            color: '#3BC56B',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata1,
                    },
                    {
                        name: '测量异常',
                        type: 'bar',
                        barGap: '60%',
                        barWidth: barWidth,
                        itemStyle: {
                            // color: 'rgba(255,164,41,.16)',
                            color: color2,
                            borderColor: color2,
                            borderWidth: 1,
                            borderType: 'solid',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            color: '#FF7844',
                            fontSize: 20,
                            textAlign: 'center',
                        },
                        zlevel: 2,
                        data: Ydata2,
                    },
                    {
                        name: '黄色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['-81%', 5],
                        z: 12,
                        color: '#3BC56B',
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                        ],
                    },
                    {
                        name: '蓝色底座',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: ['81%', 5],
                        z: 12,
                        color: '#FF7844',
                        show: false,
                        data: [
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            },
                            {
                            name: '',
                            value: '5',
                            }
                        ],
                    }
                ],
            };
            myChart.setOption(option);
            setTimeout(function (){
                window.addEventListener("resize",function(){
                    myChart.resize();
            })
            },200)
            option && myChart.setOption(option);
        })
    },
}
<template>
    <div class="medical-list">
        <roleList
        class="customer-table"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableData="this.rolelogic.roleList()"
        :tableHeader="this.rolelogic.opHeader()"
        :lazyLoad="this.rolelogic.getChildRoleList"
      >
        <template v-slot:btn="slotProps">
          <el-button type="primary" @click.stop="assignAgency(slotProps.row)">
            分配机构
          </el-button>
        </template>
      </roleList>
    </div>
      <el-dialog
        class="medical-dialogs"
        :model-value="assignform"
        center
        :before-close="noChange"
      >
      <div class="dialog-titile">请选择需要分配的机构</div>
      <el-form
           ref="editroleForm"
           style="height: 5rem;overflow-y: auto;overflow-x: hidden;margin-top: 2rem;"
           :model="agencyForm"
           :rules="this.agencylogic.rules"
         >
            <el-col>
              <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;分配机构：</span>
                  <el-form-item>
                    <el-cascader
                      v-model="agencyForm"
                      :options="options"
                      :show-all-levels="false"
                      :props="this.agencylogic.roleProps"
                      @change="areaNameChange"
                      placeholder='请选择分配的机构'
                    >
                    </el-cascader>
                  </el-form-item>
                </div>
              </el-col>
         </el-form>
        <span slot="footer" class="sureBtn" style="margin-top:-1rem;">
          <el-button class="qx" @click="noChange('editroleForm')" type="info"></el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="okChange('editroleForm')" type="primary"
            ></el-button
          >
        </span>
      </el-dialog>
  </template>
  <script>
  import roleList from "@/components/listComponents/treeList";
  import top from "@/components/top";
  export default {
    name: "assignfun",
    components: { roleList,top },
    data() {
      return {
        assignform: false,
        props: {
          children: "children",
          label: "functionName",
        },
        roleid: "",
        agencyForm:'',
        options:[],
      };
    },
    mounted() {
      var aa = JSON.parse(window.sessionStorage.getItem("agencyList")) 
      this.projectList = aa;
      let nodes = this.projectList.map((item) => ({
        value: item.id,
        label: item.agencyName,
        leaf: item.hasChildren === false,
      }));
      this.options = nodes;
    },
    methods: {
        areaNameChange (e) {
        this.agencyForm = e[e.length - 1];
        let aa = {
                agencyName: '',
                agencyCode: '',
                agencyParentId: this.agencyForm
            }
        this.axios
            .post(this.baseURL + "Agency/AgencyList", aa, this.headers())
            .then((res) => {
            this.resolveData(res.data);
            });
        },
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
      assignAgency(row) {
        this.assignform = true;
        this.roleid = row.id
      },
  
      noChange() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.assignform = false;
            done();
          })
          .catch((_) => {});
      },
      okChange(ref) {
        this.$refs[ref].validate((valid) => {
         if (valid) {
            this.assignform = false;
            var aa = {
                agencyId: this.agencyForm,
                roleId: this.roleid
            }
            this.axios
            .post(this.baseURL + "Role/AssginAgency", aa, this.headers())
            .then((res) => {
                this.resolveData(res.data, "assign");
            });
         }
        });
      },
    },
  };
  </script>
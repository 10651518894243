<template>
    <div class="medical-list">
       <roleList
        class="customer-table"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableData="this.rolelogic.roleList()"
        :tableHeader="this.rolelogic.opHeader()"
        :lazyLoad="this.rolelogic.getChildRoleList"
       >
       <template v-slot:btn="slotProps">
           <el-button
           type="primary"
           v-if="slotProps.row.rpid != emptyId"
           @click.stop="editrole(slotProps.row)"
           >
           修改角色
           </el-button>
       </template>
       </roleList>
   </div>
     <el-dialog
        class="medical-dialogs edit-dialog"
       :model-value="editroleDial"
       center
       :before-close="nosureEditRole"
     >
     <div class="dialog-titile">修改角色</div>
       <div class="xgmm_form" style="margin-top: 20%">
         <el-form
           ref="editroleForm"
           :model="roleForm"
           :rules="this.rolelogic.rules"
         >
           <el-row
               style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.3rem"
           >
             <el-col :span="24" :md="11" >
               <div class="addform-box">
                 <span style="font-size: 0.25rem;"><i>*</i>上级角色：</span>
                 <el-form-item prop="rpName">
                   <div class="edit-select-box">
                     <el-cascader
                         v-model="roleForm.rpName"
                         :options="roleTopNode"
                         :show-all-levels="false"
                         :props="this.rolelogic.roleProps"
                         @change="roleChange"
                         :placeholder="rpName"
                     >
                     </el-cascader>
                   </div>
                 </el-form-item>
               </div>
             </el-col>
             <el-col :span="24" :md="10">
               <div class="addform-box">
                 <span style="font-size: 0.25rem;"><i>*</i>角色名称：</span>
                 <el-form-item prop="roleName">
                   <div>
                     <el-input
                         class="admin_tel input77"
                         v-model="roleForm.roleName"
                         maxlength="30"
                     >
                     </el-input>
                   </div>
                 </el-form-item>
               </div>
             </el-col>
           </el-row>
         </el-form>
       </div>
       <div class="sureBtn" style="margin-top: 17%;">
         <el-button
           class="qx"
           @click="nosureEditRole('editroleForm')"
           type="info"
           ></el-button
         >
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <el-button
           class="qd"
           @click="sureEditRole('editroleForm')"
           type="primary"
           ></el-button
         >
       </div>
     </el-dialog>
 </template>
 <script>
 import roleList from "@/components/listComponents/treeList";
 export default {
   name: "edit",
   components: { roleList },
   data() {
     return {
       roleTopNode: [],
       childRoleList: [],
       editroleDial: false,
       roleForm: {},
       rpName: "",
       emptyId: this.emptyId,
     };
   },
   mounted() {
     this.rolelogic.roleList().forEach((item) => {
       this.roleTopNode.push({
         value: item.id,
         label: item.roleName,
         leaf: item.hasChildren === false,
       });
     });
   },
   methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
    },
     roleChange(e) {
       this.roleForm.rpid = e[e.length - 1];
     },
     sureEditRole(ref) {
       this.$refs[ref].validate((valid) => {
         if (valid) {
           let aa = {
             roleName: this.roleForm.roleName,
             id: this.roleForm.id,
             rpid: this.roleForm.rpid,
           };
 
           this.axios
             .post(this.baseURL + "Role/RoleEdit", aa, this.headers())
             .then((res) => {
               this.resolveData(res.data, "edit");
             });
           this.editroleDial = false;
         }
       });
     },
     nosureEditRole(ref) {
       this.$confirm("确认要关闭吗？")
         .then((_) => {
           this.editroleDial = false;
           done();
         })
         .catch((_) => {});
     },
     editrole(row) {
       let obj = { ...row };
       this.roleForm = obj;
       this.rpName = this.roleForm.rpName;
       this.editroleDial = true;
       this.resetForm("editroleForm");
     },
     resetForm(refName) {
       if (this.$refs[refName]) {
         this.$refs[refName].resetFields();
       }
     },
   },
 };
 </script>
<template>
    <div class="body">
        <Switch></Switch>
    </div>
</template>
  
<script>
import Switch from "@/components/deviceComponents/switch.vue";

export default {
name: "addUser",
    components: {
        Switch,
    },
};
</script>
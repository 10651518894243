<template>
    <div class="body">
        <edit></edit>
    </div>
</template>
  
<script>
import edit from "@/components/patientComponents/edit.vue";

export default {
name: "addUser",
components: {
    edit,
},
};
</script>
  
<template>
    <div class="body">
        <Bindingagency></Bindingagency>
    </div>
</template>
  
<script>
import Bindingagency from "@/components/deviceComponents/bindingagency.vue";

export default {
name: "addUser",
components: {
    Bindingagency,
},
};
</script>
  
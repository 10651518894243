<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane v-for="(item, index) in configList" :name=item.configTypeName :key=' index' :label="item.configTypeName">
                            <el-form style="margin-top: 10%;">
                                <div v-for="biu in item.configOptions" class="config-box">
                                    <div>
                                        <div style="color: #fff;"><i style="color:red">*</i>&nbsp;{{ biu.configIdName }}最大值：</div>
                                        <el-input
                                        v-model="biu.maxValue"
                                        placeholder="请输入最大值"
                                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                        maxlength="30"
                                        />
                                    </div>
                                    <div>
                                        <div style="color: #fff;"><i style="color:red">*</i>&nbsp;{{ biu.configIdName }}最小值：</div>
                                        <el-input
                                        v-model="biu.minValue"
                                        placeholder="请输入最小值"
                                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                        maxlength="30"
                                        />
                                    </div>
                                </div>
                                <div class="config-box">
                                    <div>
                                        <div style="color: #fff; font-size: 0.2rem;"><i style="color:red">*</i>&nbsp;初始化手表功能频率：</div>
                                        <el-input
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                            v-model="item.minute"
                                            placeholder="请输入手表功能频率（分钟）"
                                            maxlength="30"
                                            />
                                    </div>
                                </div>
                                <div style="display: flex;justify-content: space-between;width: 30%;margin: auto;margin-top: 1%;">
                                    <div style="color: #fff;font-size: 0.26rem;"><i style="color:red">*</i>&nbsp;开关：</div>
                                    <el-switch
                                    v-model="item.isEnable"
                                    inactive-color="#585858">
                                    </el-switch> 
                                </div>
                            </el-form>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="批量初始化手表功能频率" name="first" class="czpeizhi">
                            <div style="margin-top: 10%;">
                                <div style="color: #fff;"><i style="color:red">*</i>&nbsp;批量初始化手表功能频率：</div>
                                <el-input
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="timer"
                                placeholder="请输入手表功能频率（分钟）"
                                maxlength="30"
                                />
                            </div>
                            <div class="sureBtn" style="margin-top: 5%;">
                                <el-button class="qd" @click="czConfig()" type="primary"></el-button>
                            </div>
                        </el-tab-pane> -->
                    </el-tabs>     
                    <div class="sureBtn" style="margin-top: 5%;" v-if="okcz">
                        <el-button class="qd" @click="okEditConfig()" type="primary"></el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
export default {
    components: {
        basecom,
        top
    },
    data() {
        return {
            activeName:'血压',
            configList:'',
            biulist:{},
            flg:1,
            okcz:true,
            timer:''
        };
    },
    mounted() {
        this.handleCurrentChange();
    },
    methods: {
        czConfig(){
            var aa = {
                val:this.timer
            }

            this.axios.post(this.baseURL + 'WatchResposne/WatchBatchFrequence',aa,this.headers()).then(res=>{
                console.log(res)
                if(res.data.data.flg){
                    this.$message({
                        message: '重置成功',
                        type: 'success'
                    });
                }else{
                    this.$message({
                        message: '重置失败',
                        type: 'success'
                    });
                }
            })
        },
        okEditConfig() {
            this.flg = 1
            for(var i = 0;i < this.configList.length;i++){
                this.configList[i].minute = Number(this.configList[i].minute)
                for(var j = 0; j < this.configList[i].configOptions.length;j++){
                    if(this.configList[i].configOptions[j].maxValue == ''  ||  this.configList[i].configOptions[j].minValue == ''){
                        this.$message({
                            message: '请填写所有配置项,值不能为0',
                            type: 'warning'
                        });
                        console.log(this.configList[i].configOptions[j].maxValue)
                        console.log(this.configList[i].configOptions[j].minValue)
                        this.flg++
                    }
                }
            }
            if(this.flg == 1){
                var aa = {
                    configList:this.configList
                }
                this.axios
                .post(this.baseURL + "Config/ConfigEdit", aa, this.headers())
                .then((res) => {
                    this.resolveData(res.data, "edit");
                });
            }
       },
        handleClick(tab, event) {
            if(tab.props.name == 'first'){
                this.okcz = false
            }else{
                this.okcz = true
            }
        },
        handleCurrentChange: function (page = 1) {
            this.configlogic.searchObj.page = page;
            this.configlogic.getconfigList().then((res) => {
                this.configList = res.configList;
            });
        },
    },
    };
</script>
<template>
    <div class="top-box-top">
        <div class="top-left">

        </div>
        <div class="top-phone-left">
            <el-button type="primary" size="default"  @click="drawer = true">列表</el-button>
        </div>
        <div class="top-phone-right">
            <el-button type="primary" size="default" @click="">退出</el-button>
        </div>
        <div class="top-right">
            <div class="operate">
                <div class="top-button">
                  <el-button type="primary" @click="statistics()" v-if="gotoshow">数据统计</el-button>
                  <el-button type="primary" @click="back()" v-if="backshow">返回</el-button>
                </div>
                <div class="admin-box" @click="editbox = !editbox">
                <img src="../assets/img/adminicon.png" alt="" style="margin-top: 0.05rem;">
                <span>{{ this.session("username") }}</span>
                <img style="height: 0.5rem;width:0.5rem" src="../assets/img/bottomicon.png" alt="" v-if="editbox">
                <img style="height: 0.5rem;width:0.5rem" src="../assets/img/topicon.png" alt="" v-if="!editbox">
                </div>
                <div class="admin-gang">
                </div>
                <div class="esc-box" @click="callfun(this.imgBtnList[1].fAction)">
                <img src="../assets/img/editicon.png" alt="" style="margin-top: 0.05rem;">
                <span>退出</span>
                </div>
            </div>
            <div class="user-box" @click="editPwd()" v-if="editbox">
                修改密码
            </div>
        </div>
    <el-drawer
       v-if='drawer'
       :model-value="drawer"
       :before-close="handleClose"
       :with-header="false">
       <left></left>
     </el-drawer>
    </div>
   
    <el-dialog
       class="medical-dialogs"
       :model-value="showEditPwd"
       center
       :before-close="noEditPwd"
     >
       <div>
         <div class="dialog-titile">修改密码</div>
         <div class="setpwd">
           <el-form ref="editPwdForm" :model="PwdFrom" :rules="rules" style="margin-top: 10%;">
             <div class="Pwdfrom-input from-input from-input-dialog">
               &nbsp;<span><i>*</i>&nbsp;原密码：</span>
               <el-form-item prop="oldPwd">
                 <el-input
                   v-model="PwdFrom.oldPwd"
                   placeholder="请输入原密码"
                   maxlength="30"
                   show-password
                 />
               </el-form-item>
             </div>
             <div class="Pwdfrom-input from-input from-input-dialog">
               &nbsp;<span><i>*</i>&nbsp;新密码：</span>
               <el-form-item prop="newPwd">
                   <el-input
                     v-model="PwdFrom.newPwd"
                     placeholder="请输入新密码"
                     maxlength="30"
                     show-password
                   />
                 </el-form-item>
             </div>
             <div class="Pwdfrom-input from-input from-input-dialog">
               &nbsp;<span><i>*</i>&nbsp;新密码：</span>
               <el-form-item prop="newPwdAgin">
                   <el-input
                     v-model="PwdFrom.newPwdAgin"
                     placeholder="请再次输入新密码"
                     maxlength="30"
                     show-password
                   />
               </el-form-item>
             </div>
           </el-form>
         </div>
         <div class="sureBtn" style="margin-top: 10%;">
           <el-button class="qx" @click="noEditPwd()" type="info"></el-button>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <el-button class="qd" @click="okEditPwd('editPwdForm')" type="primary"></el-button>
         </div>
       </div>
    </el-dialog>
 </template>
 
 <script>
 import left from './left.vue'
 export default {
   components:{
     left
   },
   data(){
     return{
       drawer: false,
       editbox:false,
       imgBtnList: [],
       showEditPwd:false,
       url: window.location.href,
       PwdFrom: {
         oldPwd: "",
         newPwd: "",
         newPwdAgin: "",
       },
       rules: {
         oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
         newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
         newPwdAgin: [
           { required: true, message: "请再次输入新密码", trigger: "blur" },
         ],
       },
       gotoshow:false,
       backshow:false
     }
   },
   mounted(){
     this.checkToken();
     this.loadFunBtnList()
     this.load()
     
   },
   dataDestroy() {   
     if (this.timer) {
       clearInterval(this.timer)
     }
   },
   methods:{
    load() {
      let href = window.location.href;
      if (href.indexOf("Statistics") > 0) {
        this.backshow = true;
      }else{
        this.gotoshow = true;
      }
    },
    back(){
      this.$router.go(-1)
    },
    statistics(){
      this.$router.push("/Statistics.html");
    },
    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.drawer = false
          })
          .catch(_ => {});
    },
    checkToken () {
        let token = this.session("token");
        if (token == "" || token == null) {
          setTimeout(() => {
            this.$router.push("/login.html");
          }, 1500);
        } else
          this.axios
            .post(this.baseURL + "User/CheckToken", null, this.headers())
            .then((res) => {
              this.resolveData(res.data, "checkToken");
            });
    },
    loadFunBtnList() {
      let funbtnlist = JSON.parse(this.session("funbtnList"));
      if (funbtnlist != null) {
        funbtnlist = funbtnlist.filter((e) => e.isShow);
        this.imgBtnList = this.sortByKey(funbtnlist, "fOrder");
      }
    },
    callfun(fun) {
      this[fun]();
    },
    logOut() {
      this.axios
        .post(this.baseURL + "User/LogOut", null, this.headers())
        .then((res) => {
          this.resolveData(res.data, "logout")
        
          sessionStorage.setItem("username", "");
          sessionStorage.setItem("userpassword", "");
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("agencyList", "");
          sessionStorage.setItem("functionList", "");
          sessionStorage.setItem("funbtnList", "");
          sessionStorage.setItem("roleList", "");
          sessionStorage.setItem("mbDhl", "");
          sessionStorage.setItem("delArr", "");
          sessionStorage.setItem("fpid", "");
          sessionStorage.setItem("fpname", "");
          sessionStorage.setItem("menuid", "");
          sessionStorage.setItem("breadcrumb", "");
        });
    },
    editPwd() {
      this.showEditPwd = true;
    },
    noEditPwd() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.showEditPwd = false;
          done();
        })
        .catch((_) => {});
    },
    okEditPwd(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(this.baseURL + "User/EditPwd", this.PwdFrom, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
          this.showEditPwd = false;
        } else {
          return false;
        }
      });
    },
   }
 }
 </script>
<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <patientSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></patientSearch>
                  </div>
                  <patientList
                  class="customer-table"
                  :header-cell-style="{ 
                  background:'#0A1634',color:'#fff'}"
                  :tableHeader="this.patientlogic.opHeader()"
                  :tableData="PatientList"
                  :page="this.patientlogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                    <template v-slot:btn="slotProps">
                        <el-button type="primary" @click="editPatient(slotProps.row)">
                            修改人员
                        </el-button>
                      </template>
                  </patientList>
                  <pager
                  :page="this.patientlogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
      <el-dialog
        class="medical-dialogs edit-dialog"
        :model-value="editPatientFrom"
        center
        :before-close="nosureEditPatient"
      >
      <div class="dialog-titile">修改人员信息</div>
         <el-form ref="editPatient" :model="PatientFrom" :rules="this.patientlogic.rules" style="overflow: auto;height: 5.5rem;">
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;人员名称：</span>
                <el-form-item prop="userName">
                  <div>
                    <el-input
                        v-model="PatientFrom.userName"
                        placeholder="请输入姓名"
                        maxlength="30"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
                <el-form-item prop="genderType">
                  <div>
                    <el-select
                      v-model="PatientFrom.genderType"
                      class="admin_tel input77"
                    >
                      <el-option
                        v-for="item in this.patientlogic.TF"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;出生日期：</span>
                <el-form-item prop="birthday">
                  <div>
                    <el-date-picker
                    format="YYYY-MM-DD"
                    v-model="PatientFrom.birthdays"
                    type="date"
                    :placeholder=PatientFrom.birthday>
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;身份证号：</span>
                <el-form-item prop="idCard">
                  <div>
                    <el-input
                        v-model="PatientFrom.idCard"
                        placeholder="请输入身份证号"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;身&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高：</span>
                <el-form-item prop="height">
                  <div>
                    <el-input
                        v-model="PatientFrom.height"
                        placeholder="请输入身高"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;体&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重：</span>
                <el-form-item prop="height">  
                <div>
                    <el-input
                        v-model="PatientFrom.weight"
                        placeholder="请输入体重"
                        oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureEditPatient('editPatient')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureEditPatient('editPatient')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
  </template>
  
  <script>
  import basecom from "@/components/base";
  import patientList from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import patientSearch from "@/components/searchComponents/patientSearch.vue";
  export default {
    components: {
      patientList,
      pager,
      patientSearch,
      top,
      basecom
    },
    data() {
      return {
        editPatientFrom: false,
        PatientFrom: {},
        PatientList: [],
        page: 1,
        numTotal: 0,
        TF: [
          { value: false, label: "否" },
          { value: true, label: "是" },
        ],
      };
    },
    mounted() {
      this.reset();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.patientlogic.searchObj.page = page;
        this.patientlogic.getPatientList().then((res) => {
          
          res.patientList.forEach(item=>{
            item.height = parseFloat(item.height).toFixed(2)
            item.weight = parseFloat(item.weight).toFixed(2)
            item.Cardid = item.idCard
            item.Cardid = item.Cardid.replace(/(\w{6})\w*(\w{4})/, "$1********$2")
            item.heights = item.height + '          '  + 'cm'
            item.weights = item.weight + '          '  + 'kg'
          })
              
          this.PatientList = res.patientList;
          this.numTotal = res.total;
        });
      },
      sureEditPatient(ref) {
        let bb = this.PatientFrom;
        bb.gender = bb.genderType
        delete bb.heights
        delete bb.weights
        delete bb.Cardid
        delete bb.createDate
        delete bb.genderType
        delete bb.genderTypeName
        if(bb.birthdays != undefined){
          bb.birthday = bb.birthdays
          var y = bb.birthday.getFullYear();
          var m = bb.birthday.getMonth() + 1;
          m = m < 10 ? ('0' + m) : m;
          var d = bb.birthday.getDate();
          d = d < 10 ? ('0' + d) : d;
          var h = bb.birthday.getHours();
          var minute = bb.birthday.getMinutes();
          minute = minute < 10 ? ('0' + minute) : minute;
          bb.birthday = y + '-' + m + '-' + d
          delete bb.birthdays
        }
        switch(bb.gender){
          case "女":
            bb.gender = 2;
          break;
          case "男":
            bb.gender = 1;
          break;
        }
        console.log(bb)
        var date = new Date().getTime();
          var cc = Date.parse(new Date(bb.birthday));
          if(date > cc){
            if(bb.height != 0 && bb.weight != 0  ){
              console.log(bb)
                this.$refs[ref].validate((valid) => {
                  if (valid) {
                    this.axios
                      .post(this.baseURL + "Patient/PatientEdit", bb, this.headers())
                      .then((res) => {
                        this.resolveData(res.data, "edit");
                      });
                    this.editPatientFrom = false;
                  } else {
                    return false;
                  }
                });
              }
        }else{
            this.$message({
                message: `出生日期不能大于当前时间`,
                type: "warning",
            });
          }
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      nosureEditPatient() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editPatientFrom = false;
            done();
          })
          .catch((_) => {});
      },
      editPatient(row) {
        this.PatientFrom = {}
        this.editPatientFrom = true;
        let obj = { ...row };
        this.mbID = row.id;
        this.PatientFrom = obj
        this.PatientFrom.genderType = obj.genderTypeName
        this.resetForm("editPatient");
      },
      reset() {
        this.patientlogic.reset();
        this.handleCurrentChange();
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  
<template>
    <div id="Base">
      <left></left>
    </div>
  </template>
  
<script>
import left from "@/components/left";
export default {
name: "Base",
components: {
    left,
},
};
</script>
  
  
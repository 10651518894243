<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <el-upload
                        ref="devicefile"
                        drag
                        class="upload-demo"
                        :http-request="upload"
                        action="none"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                        将Excel文件拖到此处，或<em>点击上传</em>
                        </div>
                        <div
                        class="el-upload__tip"
                        slot="tip"
                        style="color: crimson; font-size: 15px; text-align: center"
                        >
                        *只能上传Excel文件
                        </div>
                    </el-upload>
                    <el-dialog
                    class="medical-dialogs"
                        :model-value="showDeviceList"
                        top="0rem"
                        center
                        style="width: 80%; !important"
                        :before-close="reset"
                    >
                        <div class="uploadlist">
                        <devicelist
                          :header-cell-style="{ 
                          background:'#0A1634',color:'#fff'}"
                          :tableHeader="this.agencylogic.uploadlist()"
                          :tableData="
                          deviceList.slice((page - 1) * this.PageSize, page * this.PageSize)
                          "
                          :page="page"
                          :row-class-name="tableRowClassName"
                        />
                        <pager
                            :page="page"
                            :numTotal="numTotal"
                            :handleCurrentChange="handleCurrentChange"
                            style="height: 10%;display: flex;justify-content: center;align-items: center;"
                        />
                        <div class="sureBtn" style="height: 10%;margin-top: 0;">
                            <el-button class="qx" @click="reset" type="success"></el-button>
                            <el-button class="qd" @click="okAddDevice" type="primary"
                            ></el-button
                            >
                        </div>
                        </div>
                    </el-dialog>
                    <el-dialog
                      width="50%"
                      :model-value="showProgress"
                      center
                      :before-close="holdon"
                      class="upload-dialog"
                    >
                        <el-row style="color: crimson; font-size: 15px; text-align: center">
                        <el-col
                            ><el-tag type="success">{{ this.tagtext }}</el-tag></el-col
                        >

                        <el-col>
                            <el-progress type="circle" :percentage="percentage" />
                        </el-col>
                        </el-row>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import devicelist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager";
export default {
    components: {
        basecom,
        top,
        devicelist,
        pager
    },
    data() {
        return {
            uploadPath: this.baseURL + "Watch/ImportWatchExcel",
            savePath: this.baseURL + "Watch/SaveImportWatch",
            showDeviceList:false,
            showProgress:false,
            deviceList: [],
            page: 1,
            numTotal: 0,
            map: {},
            percentage: 1,
            enddevlist: [],
            tagtext: "",
        };
    },
    created(){
    },
    mounted() {
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      upload(file) {
          let fileobj = file.file;
          let filetype = fileobj.name.substring(fileobj.name.lastIndexOf(".") + 1);
          if (filetype != "xls" && filetype != "xlsx")
              this.$message({
              message: "上传文件必须为Excel文件",
              type: "error",
              });
          else {
              let formdata = new FormData();
              formdata.append("file", fileobj);
              this.axios
              .post(this.uploadPath, formdata, this.headers())
              .then((res) => {
                  let data = this.resolveData(res.data);
                  this.deviceList = data.excelWatchList;
                  this.numTotal = this.deviceList.length;
                  this.showDeviceList = true;
              });
          }
      },
      handleCurrentChange(page) {
          this.page = page;
      },
      reset(done) {
          this.$confirm("确认要关闭吗？关闭后将需要重新上传Excel文件")
              .then((_) => {
              this.deviceList = [];
              this.showDeviceList = false;
              this.page = 1;
              this.numTotal = 0;
              })
              .catch((_) => {});
      },
      holdon(done) {
          this.$message({
              message: "操作中,请稍后...",
              type: "warning",
          });
      },
      okAddDevice(ref) {
        this.$confirm("确认要保存吗？")
          .then((_) => {
            this.showProgress = true;
            this.tagtext = "请稍后......";
            setTimeout(() => {
                this.endSave()
            }, 2000);
          })
          .catch((_) => {});
      },
      endSave() {
        this.tagtext = "数据写入中,请稍后......";
        this.percentage = 50
        var aa = {
          excelWatchList:this.deviceList
        }
        this.axios.post(this.savePath, aa, this.headers()).then((res) => {
            this.resolveData(res.data, "add");
            if(res.data.flg){
                this.percentage = 100
            }else{
                this.showProgress = false
            }
        });
      },
    },
};
</script>
<template>
    <div class="body">
        <deletedevice></deletedevice>
    </div>
</template>
  
<script>
import deletedevice from  "@/components/deviceComponents/deletedevice.vue"

export default {
name: "addUser",
components: {
    deletedevice,
},
};
</script>
  
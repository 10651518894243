<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
              测量记录列表·共&nbsp;<span style="color: #FFA70D;font-size: 0.25rem;">{{ numTotal }}</span>&nbsp;条
            </div>
          </el-col>
        </div> 
        <div class="searchlist">
          <el-col :span="3.5">
            <el-select v-model="this.rSeQCType"  @change="setuserName" placeholder="请选择测量类型">
                <el-option
                v-for="item in this.patienttype"
                :key="item.key"
                :label="item.value"
                :value="item.key">
                </el-option>
            </el-select>
          </el-col>
          <el-col :span="1.5">
            <div class="chaxun-button" @click="search"></div>
          </el-col>
          <el-col :span="1.5">
            <div class="chongzhi-button" @click="reset"></div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        userName: "",
        email: "",
        mobile: "",
        rSeQCType: "",
        patienttype:[]
      };
    },
    props: {
      numTotal: {
        type: Number,
        default: 0,
      },
      rSeQCType: {
        type: String,
        default: "",
      },
      search: {
        type: Function,
        default: function () {},
      },
      reset: {
        type: Function,
        default: function () {},
      },
    },
    mounted() {
      this.load();
      this.axios.get(this.baseURL + 'Patient/TestTypeList',this.headers()).then(res=>{
          console.log(res)
          this.patienttype = res.data.data.enums
      })
    },
    methods: {
      load() {
        this.patientlogic.reset();
      },
      setuserName() {
        this.patientlogic.measureSearchObj.testType = this.rSeQCType;
        let interval = setInterval(() => {
          if (this.patientlogic.measureSearchObj.testType == '-99') {
            this.rSeQCType = "";
            clearInterval(interval);
          }
        }, 1000);
      },
    },
  };
  </script>
  <style>
.bangdinginput > .el-input__wrapper{
  box-shadow: none !important;
    background: none !important;
    border: 1px solid #00BAFF;
}
</style>
  
  
  
<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <userSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></userSearch>
                  </div>
                  <userlist
                  class="customer-table"
                  :header-cell-style="{ 
                  background:'#0A1634',color:'#fff'}"
                  :tableHeader="this.userlogic.opHeader()"
                  :tableData="userList"
                  :page="this.userlogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="primary" @click="editUser(slotProps.row)">
                              修改用户
                          </el-button>
                      </template>
                  </userlist>
                  <pager
                  :page="this.userlogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
      <el-dialog
        class="medical-dialogs edit-dialog"
        :model-value="editUserFrom"
        center
        style="width: 90% !important;"
        :before-close="nosureEditUser"
      >
      <div class="dialog-titile">修改用户信息</div>
          <el-form ref="editUser" :model="UserFrom" :rules="this.userlogic.rules" style="overflow: auto;height: 5rem;">
            <el-row
                style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
            >
              <el-col :span="24" :md="10">
                <div class="addform-box">
                  &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;用户名称：</span>
                  <el-form-item prop="userName">
                    <div>
                      <el-input
                        v-model="UserFrom.userName"
                        placeholder="请输入用户名称"
                        maxlength="30"
                      >
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24" :md="10">
                <div class="addform-box">
                  &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;&nbsp;手&nbsp;机&nbsp;号：</span>
                  <el-form-item prop="mobile">
                    <div>
                      <el-input
                        v-model="UserFrom.mobile"
                        placeholder="请输入手机号"
                        maxlength="11"
                      >
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row
                style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
            >
              <el-col :span="24" :md="10">
                <div class="addform-box">
                  &nbsp;&nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;身份证号：</span>
                  <el-form-item prop="idCard">
                    <div>
                      <el-input
                        class="admin_tel"
                        placeholder="请输入身份证号"
                        v-model="UserFrom.idCard"
                        maxlength="18"
                      >
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24" :md="10">
                <div class="addform-box">
                  &nbsp;<span style="font-size: 0.25rem;"><i>*</i>&nbsp;电子邮箱：</span>
                  <el-form-item prop="email">
                    <div>
                      <el-input
                        placeholder="请输入电子邮箱"
                        v-model="UserFrom.email"
                        maxlength="30"
                        type="email"
                      >
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row
              style="display: flex; flex-wrap: wrap;justify-content: center;margin-top: 0.5rem"
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;短信接收状态：</span>
                <el-form-item prop="mobileReceiveStatus">
                  <div>
                    <el-select
                        v-model="UserFrom.mobileReceiveStatus"
                        placeholder="请选择"
                        class="admin_tel input77"
                      >
                        <el-option
                          v-for="item in this.userlogic.TF"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="10">
              <div class="addform-box">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;邮箱接收状态：</span>
                <el-form-item prop="emailReceiveStatus">
                  <div>
                    <el-select
                      v-model="UserFrom.emailReceiveStatus"
                      placeholder="请选择"
                      class="admin_tel input77"
                    >
                      <el-option
                        v-for="item in this.userlogic.TF"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureEditUser('editUser')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureEditUser('editUser')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
  </template>
  
  <script>
  import basecom from "@/components/base";
  import userlist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import userSearch from "@/components/searchComponents/userSearch.vue";
  export default {
    components: {
      userlist,
      pager,
      userSearch,
      top,
      basecom
    },
    data() {
      return {
        editUserFrom: false,
        UserFrom: {},
        userList: [],
        page: 1,
        numTotal: 0,
        TF: [
          { value: false, label: "否" },
          { value: true, label: "是" },
        ],
      };
    },
    mounted() {
      this.reset();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.userlogic.searchObj.page = page;
        this.userlogic.getUserList().then((res) => {
          this.userList = res.userList;
          this.numTotal = res.total;
        });
      },
      sureEditUser(ref) {
        let aa = this.UserFrom;
        aa.id = this.mbID;
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.axios
              .post(this.baseURL + "User/UserEdit", aa, this.headers())
              .then((res) => {
                this.resolveData(res.data, "edit");
              });
            this.editUserFrom = false;
          } else {
            return false;
          }
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      nosureEditUser() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editUserFrom = false;
            done();
          })
          .catch((_) => {});
      },
      editUser(row) {
        this.editUserFrom = true;
        let obj = { ...row };
        this.UserFrom = obj;
        this.mbID = row.id;
        this.resetForm("editUser");
      },
      reset() {
        this.userlogic.reset();
        this.handleCurrentChange();
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  
import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        userName: '',
        imei: ''
    },
    list:{
        id: '',
        command: ''
    },
    page: 1,
    gethomeList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Index/WatchPatientList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    WatchCommand: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "WatchResposne/WatchCommand", this.list, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = {  page: 1, userName: '', imei: '' };
    }
}
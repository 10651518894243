<template>
    <div class="body">
         <div class="top-box">
            <top />
        </div>
        <div class="bottom-box" style="display: flex;">
            <div class="echarts-left">
                <div class="echarts-left-top">
                    <people/>
                </div>
                <div class="echarts-left-bottom">
                    <activate/>
                </div>
            </div>
            <div class="echarts-center">
                <div class="echarts-center-top">
                    <devicenum/>
                </div>
                <div class="echarts-center-bottom">
                    <cell/>
                </div>
            </div>
            <div class="echarts-right">
                <agepeoplenum/>
            </div>
        </div>
    </div>
</template>

<script>
import top from "@/components/top";
import people from "@/components/statisticsComponents/people.vue"
import activate from "@/components/statisticsComponents/activate.vue"
import cell from "@/components/statisticsComponents/cell.vue"
import devicenum from "@/components/statisticsComponents/devicenum.vue"
import agepeoplenum from "@/components/statisticsComponents/agepeoplenum.vue"
export default {
    components: { top,people,activate,cell,devicenum,agepeoplenum },
    mounted(){
    }
};
</script>

import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        name: '',
        imei: '',
    },
    selectedProps: [],
    allProp: false,
    allData: false,
    pwd: "",
    searchOff:{
        id: '',
        onOffOpCode: '',
        onOff: true
    },
    TF: [
        { value: 0, label: "未充电" },
        { value: 1, label: "充电中" }
    ],
    TE: [
        { value: 0, label: "离线" },
        { value: 1, label: "在线" }
    ],
    page: 1,
    rules: {
        phone: [
            { required: true, message: "手机号不能为空", trigger: "blur" },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "change" },
            { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", }
        ],
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "userName", label: "绑定人员名称",width:180},
            { prop: "imei", label: "设备序列号",width:220},
            { prop: "onLine", label: "在线状态",width:120 },
            { prop: "phone", label: "手机号",width:160  },
            { prop: "battery", label: "设备电量",width:150 },
            { prop: "chargeStatusName", label: "设备当前状态",width:150 },
            { prop: "agencyName", label: "机构名称"},
            { prop: "createDate", label: "创建时间"},
        ];
    },
   
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:130 });
        return header;
    },
    // 设备列表
    getWatchList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Watch/WatchList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    // 设备开关列表
    getWatchOnOffList:function(){
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Watch/WatchOnOffList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    // 设备开关协议
    getWatchOnOff:function(){
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "WatchResposne/WatchOnOff", this.searchOff, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = { page: 1, name: "", imei: ""};
    }
}
<template>
    <div class="body">
        <phone></phone>
    </div>
</template>
  
<script>
import phone from "@/components/deviceComponents/phone.vue";

export default {
name: "addUser",
    components: {
        phone,
    },
};
</script>
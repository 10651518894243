<template>
    <div class="body">
        <resetuser></resetuser>
    </div>
</template>
  
<script>
import resetuser from "@/components/deviceComponents/resetuser.vue";

export default {
name: "addUser",
components: {
    resetuser,
},
};
</script>
  
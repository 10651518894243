<template>
    <div>
      <div class="left"> 
        <el-tree
          class="lefttree"
          ref="funtree"
          :data="funList"
          :props="defaultProps"
          accordion
          @node-click="handleNodeClick"
          node-key="id"
          :default-expanded-keys="menuid"
          :current-node-key="mid"
          highlight-current
        />
      </div>
    </div>
  </template>
  <script>
//   import myBus from '../logic/myBus'
  export default {
    name: "Left",
    data() {
      return {
        menuid: [],
        mid: "00000000-0000-0000-0000-000000000000",
        funList: [],
        defaultProps: {
          children: "children",
          label: "label",
        },
        drawer: false,
        direction: 'ltr',
      };
    },
    mounted() {
      this.buildfun();
      this.mid = this.session("menuid");
      this.menuid = [this.mid];
      setTimeout(() => {
        this.$refs["funtree"].setCurrentKey(this.mid);
      }, 1);
    //   const emitter = myBus()
    //   emitter.on('indexMsg',(value) => {
    //   this.drawer = !value;
    // });
    },
    methods: {
      buildfun() {
        let funList = JSON.parse(this.session("functionList"));
        if (funList != null) {
          funList = this.sortByKey(funList, "fOrder");
          funList.forEach((item) => {
            if (item.fpid == this.emptyId) {
              let fun = {
                id: item.id,
                label: item.functionName,
                fController: item.fController,
                fAction: item.fAction,
                children: this.buildchild(item.id, funList),
              };
              this.funList.push(fun);
            }
          });
        }
      },
      buildchild(fpid, funList) {
        var childrenlist = [];
        funList.forEach((item) => {
          if (item.fpid == fpid) {
            let fun = {
              id: item.id,
              label: item.functionName,
              fController: item.fController,
              fAction: item.fAction,
              children: this.buildchild(item.id, funList),
            };
  
            childrenlist.push(fun);
          }
        });
        return childrenlist;
      },
      handleNodeClick(data, node, event) {
        if (data.fAction != "") {
          sessionStorage.setItem("menuid", data.id);
          let url =
            data.fController == ""
              ? data.fAction
              : data.fController + "/" + data.fAction;
          this.$router.push(url);
        }
      },
    },
  };
  </script>
  
  <style scoped>

  </style>
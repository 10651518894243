<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div class="searchlist">
          &nbsp;&nbsp;
          <el-col :span="4.5">
            <el-input
              v-model="userName"
              placeholder="请输入姓名查询用户"
              @change="setuserName"
              @keyup.enter="search"
            >
            </el-input>
          </el-col>
          <el-col :span="1.5">
            <div class="chaxun-button" @click="search"></div>
          </el-col>
          <el-col :span="1.5">
            <div class="chongzhi-button" @click="reset"></div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        userName: "",
      };
    },
    props: {
      numTotal: {
        type: Number,
        default: 0,
      },
      search: {
        type: Function,
        default: function () {},
      },
      reset: {
        type: Function,
        default: function () {},
      },
    },
    mounted() {
      this.load();
    },
    methods: {
      load() {
        this.homelogic.reset();
      },
      setuserName() {
        this.homelogic.searchObj.userName = this.userName;
        let interval = setInterval(() => {
          if (this.homelogic.searchObj.userName === "") {
            this.userName = "";
            clearInterval(interval);
          }
        }, 1000);
      },
    },
  };
  </script>
  
  
  
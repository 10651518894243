<template>
    <div class="body">
        <Export></Export>
    </div>
</template>
  
<script>
import Export from "@/components/deviceComponents/export.vue";

export default {
name: "addUser",
    components: {
        Export,
    },
};
</script>
<template>
    <div class="body">
        <reset></reset>
    </div>
</template>
  
<script>
import reset from "@/components/deviceComponents/reset.vue";

export default {
name: "addUser",
components: {
    reset,
},
};
</script>
  

<template>
    <div class="medical-list">
        <roleList
            class="customer-table"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ 
            background:'#0A1634',color:'#fff'}"
            :tableData="this.rolelogic.roleList()"
            :tableHeader="this.rolelogic.opHeader()"
            :lazyLoad="this.rolelogic.getChildRoleList"
        >
            <template v-slot:btn="slotProps">
              <el-button type="primary" @click.stop="addrole(slotProps.row)">
                  添加子角色
              </el-button>
            </template>
        </roleList>
    </div>
      <el-dialog
        class="medical-dialogs"
        :model-value="addRoleDial"
        center
        :before-close="nosureAddRole"
      >
      <div class="dialog-titile">添加子角色</div>
        <div class="xgmm_form" style="margin-top: 20%;">
            <el-form
              ref="addroleForm"
              :model="roleForm"
              :rules="this.rolelogic.rules"
            > <el-row
               style="display: flex; flex-wrap: wrap;margin-top: 0.5rem"
           >
             <el-col>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;角色名称：</span>
                  <el-form-item prop="roleName">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入角色名称"
                      v-model="roleForm.roleName"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="sureBtn" style="margin-top: 17%;">
          <el-button class="qx" @click="nosureAddRole('addroleForm')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureAddRole('addroleForm')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
  </template>
  
  
  <script>
  import roleList from "@/components/listComponents/treeList";
  export default {
    name: "add",
    components: {
      roleList,
    },
    data() {
      return {
        addRoleDial: false,
        roleName: "",
        roleForm: {},
        roleObj: {},
      };
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      sureAddRole(ref) {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.roleForm.rpid = this.roleObj.id;
            this.axios
              .post(this.baseURL + "Role/RoleAdd", this.roleForm, this.headers())
              .then((res) => {
                this.resolveData(res.data, "add");
                this.addRoleDial = false;
              });
          } else {
            return false;
          }
        });
      },
      nosureAddRole(ref) {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.addRoleDial = false;
            done();
          })
          .catch((_) => {});
      },
  
      addrole(row) {
        this.roleObj = row;
        this.addRoleDial = true;
        this.resetForm("addroleForm");
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
    },
  };
  </script>
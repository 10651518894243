<template>
    <div class="body">
        <binding></binding>
    </div>
</template>
  
<script>
import binding from "@/components/deviceComponents/binding.vue";

export default {
name: "addUser",
    components: {
        binding,
    },
};
</script>
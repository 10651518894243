<template>
    <div class="medical-list">
       <roleList
        class="customer-table"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableData="this.agencylogic.loadagencyList()"
        :tableHeader="this.agencylogic.opHeader()"
        :lazyLoad="this.agencylogic.getChildAgencyList"
       >
       <template v-slot:btn="slotProps">
           <el-button
           type="primary"
           v-if="slotProps.row.rpid != emptyId"
           @click.stop="editrole(slotProps.row)"
           >
           修改机构
           </el-button>
       </template>
       </roleList>
   </div>
     <el-dialog
        class="medical-dialogs"
       :model-value="editroleDial"
       center
       :before-close="nosureEditRole"
     >
     <div class="dialog-titile">修改机构</div>
       <div class="xgmm_form" style="margin-top: 5%">
         <el-form
           ref="editroleForm"
           style="height: 5rem;overflow-y: auto;overflow-x: hidden;"
           :model="agencyForm"
           :rules="this.agencylogic.rules"
         >
            <el-col>
              <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;上级机构：</span>
                  <el-form-item prop="agencyParentId">
                    <el-cascader
                      v-model="agencyForm.agencyParentId"
                      :options="options"
                      :show-all-levels="false"
                      :props="this.agencylogic.roleProps"
                      @change="areaNameChange"
                      :placeholder=agencyForm.parentAgencyName
                    >
                    </el-cascader>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构名称：</span>
                  <el-form-item prop="agencyName">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构名称"
                      v-model="agencyForm.agencyName"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构编码：</span>
                  <el-form-item prop="agencyCode">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构编码"
                      v-model="agencyForm.agencyCode"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;机构所在经度：</span>
                  <el-form-item prop="longitude">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构所在经度"
                      v-model="agencyForm.longitude"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span style="font-size: 0.18rem;"><i>*</i>&nbsp;机构所在纬度：</span>
                  <el-form-item prop="latitude">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构所在纬度"
                      v-model="agencyForm.latitude"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构地址：</span>
                  <el-form-item prop="address">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构地址"
                      v-model="agencyForm.address"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="from-input Userfrom-input from-input-dialog">
                  &nbsp; &nbsp; &nbsp;<span><i>*</i>&nbsp;机构特点：</span>
                  <el-form-item prop="special">
                    <el-input
                      class="admin_tel"
                      placeholder="请输入机构特点"
                      v-model="agencyForm.special"
                      maxlength="30"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
         </el-form>
       </div>
       <div class="sureBtn">
          <el-button class="dw" @click="position()" type="warning"
            >精准定位</el-button
          >
         <el-button
           class="qx"
           @click="nosureEditRole('editroleForm')"
           type="info"
           ></el-button
         >
         <el-button
           class="qd"
           @click="sureEditRole('editroleForm')"
           type="primary"
           ></el-button
         >
       </div>
     </el-dialog>
 </template>
 <script>
 import roleList from "@/components/listComponents/treeList";
 export default {
   name: "edit",
   components: { roleList },
   data() {
     return {
       editroleDial: false,
       agencyForm: {},
       emptyId: this.emptyId,
       obj:{},
       options:[],
       areaName: "请选择机构",
       projectList:[]
     };
   },
   mounted() {
    var aa = JSON.parse(window.sessionStorage.getItem("agencyList")) 
      this.projectList = aa;
      let nodes = this.projectList.map((item) => ({
        value: item.id,
        label: item.agencyName,
        leaf: item.hasChildren === false,
      }));
      this.options = nodes;
   },
   methods: {
    areaNameChange (e) {
      this.agencyForm.agencyParentId = e[e.length - 1];
      let aa = {
            agencyName: '',
            agencyCode: '',
            agencyParentId: this.agencyForm.agencyParentId
        }
      this.axios
        .post(this.baseURL + "Agency/AgencyList", aa, this.headers())
        .then((res) => {
          this.resolveData(res.data);
        });
    },
    position() {
      let jwd = window.localStorage;
      jwd.lon = "";
      jwd.lat = "";
      let routeUrl = this.$router.resolve({
          path: "/mapDialogVisible.html",
      });
      window.open(routeUrl.href, "_blank");
      localStorage.setItem("isClosemap", false);
      window.addEventListener('storage',this.setinfo)
    },
    setinfo(e){
      if(e.storageArea.isClosemap === 'true' && e.storageArea.lon !== '0'  && e.storageArea.lat !== '0'){
          let lon = "";
          let lat = "";
          lon = window.localStorage.getItem("lon");
          lat = window.localStorage.getItem("lat");
          if (lon === window.localStorage.getItem("lon")) {
          if (lon !== "") {
              this.agencyForm.longitude = lon;
              this.agencyForm.latitude = lat;
              window.localStorage.setItem("lon", 0);
              window.localStorage.setItem("lat", 0);
          }
          }
      }
    },
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
    },
     sureEditRole(ref) {
       this.$refs[ref].validate((valid) => {
         if (valid) {
           delete this.obj.hasChildren
           delete this.obj.parentAgencyName
           delete this.obj.patientNums
           this.axios
             .post(this.baseURL + "Agency/EditAgency", this.obj, this.headers())
             .then((res) => {
               this.resolveData(res.data, "edit");
             });
           this.editroleDial = false;
         }
       });
     },
     nosureEditRole(ref) {
       this.$confirm("确认要关闭吗？")
         .then((_) => {
           this.editroleDial = false;
           done();
         })
         .catch((_) => {});
     },
     editrole(row) {
       this.agencyForm = row
       this.obj = row
       this.editroleDial = true;
       this.resetForm("editroleForm");
     },
     resetForm(refName) {
       if (this.$refs[refName]) {
         this.$refs[refName].resetFields();
       }
     },
   },
 };
 </script>
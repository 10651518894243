<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <deviceSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></deviceSearch>
                  </div>
                  <devicelist
                  class="customer-table"
                  :header-cell-style="{ 
                  background:'#0A1634',color:'#fff'}"
                  :tableHeader="this.devicelogic.opHeader()"
                  :tableData="userList"
                  :page="this.devicelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="primary" @click="editUser(slotProps.row)">
                              绑定人员
                          </el-button>
                      </template>
                  </devicelist>
                  <pager
                  :page="this.devicelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
      <el-dialog
        class="medical-dialogs edit-dialog"
        :model-value="editUserFrom"
        center
        :before-close="nosureEditUser"
      >
      <div class="dialog-titile">绑定人员</div>
      <patientSearch
        :search="searchpatient"
        :reset="resetpatient"
        :numTotal="patientnumTotal"
        ></patientSearch>
      <patientlist
        class="customer-table"
        :header-cell-style="{ 
        background:'#0A1634',color:'#fff'}"
        :tableHeader="this.patientlogic.opHeader()"
        :tableData="patientList"
        :page="this.patientlogic.searchObj.page"
        :row-class-name="tableRowClassName"
        >
            <template v-slot:btn="slotProps">
                <el-button type="primary" @click="bindingPatient(slotProps.row)">
                    绑定人员
                </el-button>
            </template>
        </patientlist>
        <pager
        :page="this.patientlogic.searchObj.page"
        :numTotal="patientnumTotal"
        :handleCurrentChange="patienthandleCurrentChange"
        >
        </pager>
      </el-dialog>
  </template>
  
  <script>
  import basecom from "@/components/base";
  import patientlist from "@/components/listComponents/pagerList";
  import devicelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
  import patientSearch from "@/components/searchComponents/patientSearch.vue";
  export default {
    components: {
      patientlist,
      pager,
      deviceSearch,
      patientSearch,
      top,
      basecom,
      devicelist
    },
    data() {
      return {
        editUserFrom: false,
        UserFrom: {},
        userList: [],
        patientList:[],
        page: 1,
        numTotal: 0,
        patientnumTotal:0,
        TF: [
          { value: false, label: "否" },
          { value: true, label: "是" },
        ],
        patientId:'',
        watchId:''
      };
    },
    mounted() {
      this.search();
    },
    methods: {
        bindingPatient(row){
            this.patientId = row.id
            var aa = {
                patientId: this.patientId,
                watchId: this.watchId
            }
            this.$confirm('确定绑定该患者吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios
                .post(this.baseURL + "Watch/WatchBinding", aa, this.headers())
                .then((res) => {
                    this.resolveData(res.data, "binding");
                });
            });
        },
        patienthandleCurrentChange: function (page = 1) {
            this.patientlogic.searchObj.page = page;
            this.patientlogic.getPatientList().then((res) => {
              res.patientList.forEach(item=>{
                item.height = parseFloat(item.height).toFixed(2)
                item.weight = parseFloat(item.weight).toFixed(2)
                item.heights = item.height + '          '  + 'cm'
                item.weights = item.weight + '          '  + 'kg'
                item.Cardid = item.idCard
                item.Cardid = item.Cardid.replace(/(\w{6})\w*(\w{4})/, "$1********$2")
              })
                this.patientList = res.patientList;
                this.patientnumTotal = res.total;
            });
        },
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.devicelogic.searchObj.page = page;
        this.devicelogic.getWatchList().then((res) => {
            res.watchList.forEach(item=>{
              item.battery = item.battery + '%'
            })
            this.userList = res.watchList;
            this.numTotal = res.total;
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      nosureEditUser() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editUserFrom = false;
            done();
          })
          .catch((_) => {});
      },
      editUser(row) {
        this.watchId = row.id
        this.editUserFrom = true;
        this.patienthandleCurrentChange()
        let obj = { ...row };
        this.UserFrom = obj;
        this.resetForm("editUser");
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
      resetpatient(){
        this.patientlogic.searchObj.userName = '';
        this.patienthandleCurrentChange()
      },
      searchpatient(){
        this.patienthandleCurrentChange()
      }
    },
  };
  </script>
<template>
    <div class="body">
        <div class="top-box">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                    <deviceSearch
                    :search="search"
                    :reset="reset"
                    :resetdevice="resetdevice"
                    :numTotal="numTotal"
                    ></deviceSearch>
                    </div>
                    <watchList
                    ref="multipleSelection"
                    class="customer-table"
                    :header-cell-style="{ 
                    background:'#0A1634',color:'#fff'}"
                    :tableHeader="this.devicelogic.listHeader()"
                    :tableData="watchList"
                    :page="this.devicelogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange"
                    :needselect="true"
                    ></watchList>
                    <pager
                    :page="this.devicelogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import watchList from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import deviceSearch from "@/components/searchComponents/deviceSearch.vue";
export default {
    components: {
        basecom,
        watchList,
        pager,
        deviceSearch,
        top
    },
    data() {
        return {
            watchList: [],
            page: 1,
            numTotal: 0,
            multipleSelection:[],

        };
    },
    mounted() {
        this.handleCurrentChange();
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach(item => {
                const id = item.id
                if (this.multipleSelection.indexOf(id) == -1) {
                    this.multipleSelection.push(id)
                }
            })
            console.log(this.multipleSelection)
        },
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.devicelogic.searchObj.page = page;
            this.page = this.devicelogic.searchObj.page
            this.devicelogic.getWatchList().then((res) => {
                res.watchList.forEach(item=>{
                    item.battery = item.battery + '%'
                })
                this.watchList = res.watchList;
                this.numTotal = res.total;
            });
        },
        resetdevice(){
            if (this.multipleSelection.length == 0)
            this.$message({
                message: "未选择设备",
                type: "error",
            });
            else
            this.$confirm("确认要为选中的设备重置配置吗？")
                .then((_) => {
                    var aa = {
                    watchList:this.multipleSelection
                }
                this.axios.post(this.baseURL + 'Watch/ResetWatchServerConfigBySMS',aa,this.headers()).then(res=>{
                    var bb = this.resolveData(res.data)
                   if(bb.flg){
                        this.$message({
                            message: "重置成功",
                            type: "success",
                        });
                        this.devicelogic.searchObj.page = this.page
                        this.devicelogic.getWatchList().then((res) => {
                            res.watchList.forEach(item=>{
                                item.battery = item.battery + '%'
                            })
                            this.watchList = res.watchList;
                            this.numTotal = res.total;
                        });
                   }
                })
            })
            .catch((_) => {});
            
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
    },
    };
</script>
<template>
    <div class="devicenum-top"></div>
    <div class="devicenum-bottom" >
        <div class="device-num-box" v-for="item in WatchStatusStat">
            <div class="device-num-box-left">
                <img src="../../assets/img/zcdevicedian.png" alt="" v-if="item.watchStatTypeName=='正常'">
                <img src="../../assets/img/zcdevicebj.png" alt="" v-if="item.watchStatTypeName=='正常'">
                <img src="../../assets/img/yjdevicedian.png" alt="" v-if="item.watchStatTypeName=='离线'">
                <img src="../../assets/img/yjdevicebj.png" alt="" v-if="item.watchStatTypeName=='离线'">
                <img src="../../assets/img/bjdevicedian.png" alt="" v-if="item.watchStatTypeName=='未绑定'">
                <img src="../../assets/img/bjdevicebj.png" alt="" v-if="item.watchStatTypeName=='未绑定'">
                <img src="../../assets/img/qbdevicedian.png" alt="" v-if="item.watchStatTypeName=='总数'">
                <img src="../../assets/img/qbdevicebj.png" alt="" v-if="item.watchStatTypeName=='总数'">
                <span>{{item.watchStatTypeName}}</span>
            </div>
            <div class="device-num-box-right"  :style="{ color:item.watchStatTypeName == '正常'? '#69FFB7': item.watchStatTypeName == '离线' ? '#FF6C00' : item.watchStatTypeName == '未绑定'? '#FF2F2F' :  item.watchStatTypeName == '总数'}">
                {{item.count}}
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            WatchStatusStat:''
        }
    },
    mounted(){
        this.devicedata()
    },
    methods:{
        devicedata(){
            this.chartslogic.WatchnumconfigList().then((res) => {
                this.WatchStatusStat = res.watchStatusStatTypeList
            })
        }
    }
}
</script>
<style>

</style>
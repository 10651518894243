<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
              人员列表·共&nbsp;<span style="color: #FFA70D;font-size: 0.25rem;">{{ numTotal }}</span>&nbsp;人
            </div>
          </el-col>
        </div> 
        <div class="searchlist">
          <el-col :span="3.5">
            <el-input
            class="bangdinginput"
              v-model="userName"
              placeholder="请输入人员名称"
              @change="setuserName"
              @keyup.enter="search"
            >
            </el-input>
          </el-col>
          <el-col :span="1.5">
            <div class="chaxun-button" @click="search"></div>
          </el-col>
          <el-col :span="1.5">
            <div class="chongzhi-button" @click="reset"></div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        userName: "",
        email: "",
        mobile: "",
      };
    },
    props: {
      numTotal: {
        type: Number,
        default: 0,
      },
      search: {
        type: Function,
        default: function () {},
      },
      reset: {
        type: Function,
        default: function () {},
      },
    },
    mounted() {
      this.load();
    },
    methods: {
      load() {
        this.patientlogic.reset();
      },
      setuserName() {
        this.patientlogic.searchObj.userName = this.userName;
        let interval = setInterval(() => {
          if (this.patientlogic.searchObj.userName === "") {
            this.userName = "";
            clearInterval(interval);
          }
        }, 1000);
      },
    },
  };
  </script>
  <style>
.bangdinginput > .el-input__wrapper{
  box-shadow: none !important;
    background: none !important;
    border: 1px solid #00BAFF;
}
</style>
  
  
  